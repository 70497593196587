import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative overflow-hidden" }
const _hoisted_2 = { class: "flex justify-between items-end border-b dark:border-gray-900 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalTabs = _resolveComponent("BalTabs")
  const _component_FarmDepositForm = _resolveComponent("FarmDepositForm")
  const _component_SuccessOverlay = _resolveComponent("SuccessOverlay")
  const _component_FarmWithdrawForm = _resolveComponent("FarmWithdrawForm")
  const _component_BalCard = _resolveComponent("BalCard")

  return (_openBlock(), _createBlock(_component_BalCard, { noPad: "" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_BalTabs, {
            modelValue: _ctx.activeTab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = $event)),
            tabs: _ctx.tabs,
            class: "pt-4 -mb-px",
            "no-pad": ""
          }, null, 8, ["modelValue", "tabs"])
        ]),
        (_ctx.activeTab === 'deposit')
          ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_FarmDepositForm, {
                pool: _ctx.pool,
                onSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleInvestment($event)))
              }, null, 8, ["pool"]),
              (_ctx.investmentSuccess)
                ? (_openBlock(), _createBlock(_component_SuccessOverlay, {
                    key: 0,
                    title: _ctx.$t('farmDepositSettled'),
                    description: _ctx.$t('farmDepositSuccess'),
                    closeLabel: _ctx.$t('close'),
                    explorerLink: _ctx.explorer.txLink(_ctx.txHash),
                    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.investmentSuccess = false))
                  }, null, 8, ["title", "description", "closeLabel", "explorerLink"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 'withdraw')
          ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_FarmWithdrawForm, {
                pool: _ctx.pool,
                onSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleWithdrawal($event)))
              }, null, 8, ["pool"]),
              (_ctx.withdrawalSuccess)
                ? (_openBlock(), _createBlock(_component_SuccessOverlay, {
                    key: 0,
                    title: _ctx.$t('farmWithdrawalSettled'),
                    description: _ctx.$t('farmWithdrawalSuccess'),
                    closeLabel: _ctx.$t('close'),
                    explorerLink: _ctx.explorer.txLink(_ctx.txHash),
                    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.withdrawalSuccess = false))
                  }, null, 8, ["title", "description", "closeLabel", "explorerLink"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}