
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { EXTERNAL_LINKS } from '@/constants/links';
import TokenSearchInput from '@/components/inputs/TokenSearchInput.vue';
import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import usePools from '@/composables/pools/usePools';
import useWeb3 from '@/services/web3/useWeb3';
import usePoolFilters from '@/composables/pools/usePoolFilters';
import { masterChefContractsService } from '@/services/farm/master-chef-contracts.service';
import BalBtn from '@/components/_global/BalBtn/BalBtn.vue';
import BalAlert from '@/components/_global/BalAlert/BalAlert.vue';
import useBeethovenxConfig from '@/composables/useBeethovenxConfig';

export default defineComponent({
  components: {
    BalAlert,
    BalBtn,
    TokenSearchInput,
    PoolsTable
  },

  setup() {
    // COMPOSABLES
    const router = useRouter();
    const { isWalletReady, isV1Supported } = useWeb3();
    const { beethovenxConfig } = useBeethovenxConfig();
    const {
      selectedTokens,
      addSelectedToken,
      removeSelectedToken
    } = usePoolFilters();

    const {
      poolsWithFarms,
      onlyPoolsWithFarms,
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      isLoadingFarms,
      loadMorePools,
      poolsHasNextPage,
      poolsIsFetchingNextPage
    } = usePools();

    //TODO: this will break down once pagination starts happening
    const communityPools = computed(() => {
      return poolsWithFarms.value?.filter(
        pool => !beethovenxConfig.value.incentivizedPools.includes(pool.id)
      );
    });

    // COMPUTED
    const filteredPools = computed(() => {
      return selectedTokens.value.length > 0
        ? poolsWithFarms.value?.filter(pool => {
            return (
              selectedTokens.value.every((selectedToken: string) =>
                pool.tokenAddresses.includes(selectedToken)
              ) && beethovenxConfig.value.incentivizedPools.includes(pool.id)
            );
          })
        : poolsWithFarms?.value.filter(pool =>
            beethovenxConfig.value.incentivizedPools.includes(pool.id)
          );
    });

    const hideV1Links = computed(() => !isV1Supported);

    const poolsWithUserInFarm = computed(() => {
      return onlyPoolsWithFarms.value.filter(pool => pool.farm.stake > 0);
    });

    const hasUnstakedBpt = computed(() =>
      userPools.value.find(pool => pool.farm && parseFloat(pool.shares) > 0)
    );

    masterChefContractsService.beethovenxToken.getCirculatingSupply();

    function goToPoolCreate() {
      router.push({ name: 'pool-create' });
    }

    return {
      // data
      filteredPools,
      poolsWithUserInFarm,
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      isLoadingFarms,

      // computed
      isWalletReady,
      hideV1Links,
      poolsHasNextPage,
      poolsIsFetchingNextPage,
      selectedTokens,
      hasUnstakedBpt,

      //methods
      router,
      loadMorePools,
      addSelectedToken,
      removeSelectedToken,
      communityPools,
      goToPoolCreate,

      // constants
      EXTERNAL_LINKS
    };
  }
});
