import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "flex items-center flex-wrap" }
const _hoisted_2 = { class: "flex items-center flex-wrap" }
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-400 overflow-x-auto"
}
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = {
  key: 1,
  class: "text-gray-400 flex flex-wrap py-3"
}
const _hoisted_7 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")
  const _component_BalBtn = _resolveComponent("BalBtn")
  const _component_BalAsset = _resolveComponent("BalAsset")
  const _component_BalChip = _resolveComponent("BalChip")
  const _component_SelectTokenModal = _resolveComponent("SelectTokenModal")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_BalBtn, {
          color: "white",
          size: "sm",
          onClick: _ctx.onClick,
          class: "mr-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalIcon, {
              name: "search",
              size: "sm",
              class: "mr-2"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('filterByToken')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.modelValue, (token) => {
          return (_openBlock(), _createBlock(_component_BalChip, {
            class: "mr-2",
            key: token,
            color: "white",
            iconSize: "sm",
            closeable: true,
            onClosed: ($event: any) => (_ctx.$emit('remove', token))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalAsset, {
                address: token,
                size: 20,
                class: "flex-auto"
              }, null, 8, ["address"]),
              _createVNode("span", _hoisted_3, _toDisplayString(_ctx.tokens[token]?.symbol), 1)
            ]),
            _: 2
          }, 1032, ["onClosed"]))
        }), 128))
      ]),
      (_ctx.account && !_ctx.dynamicDataLoading && !_ctx.hasNoBalances)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            _createVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('inYourWallet')), 1),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.sortedBalances, (token) => {
              return (_openBlock(), _createBlock("span", {
                key: `wallet-${token.symbol}`,
                class: "mr-6 cursor-pointer hover:text-green-500",
                onClick: ($event: any) => (_ctx.addToken(token.address))
              }, _toDisplayString(token?.symbol), 9, ["onClick"]))
            }), 128))
          ]))
        : (_ctx.whiteListedTokens.length > 0)
          ? (_openBlock(), _createBlock("div", _hoisted_6, [
              _createVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('popularBases')), 1),
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.whiteListedTokens, (token) => {
                return (_openBlock(), _createBlock("span", {
                  key: `popular-${token.symbol}`,
                  class: "mr-3 md:mr-4 cursor-pointer hover:text-gray-700 dark:hover:text-white transition-colors",
                  onClick: ($event: any) => (_ctx.addToken(token.address))
                }, _toDisplayString(token?.symbol), 9, ["onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (_ctx.selectTokenModal)
        ? (_openBlock(), _createBlock(_component_SelectTokenModal, {
            key: 0,
            "excluded-tokens": _ctx.modelValue,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTokenModal = false)),
            onSelect: _ctx.addToken
          }, null, 8, ["excluded-tokens", "onSelect"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}