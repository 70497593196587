
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BalToggle',

  inheritAttrs: false,

  emits: ['update:modelValue', 'toggle'],

  props: {
    name: { type: String, required: true },
    modelValue: { type: Boolean, default: false },
    label: { type: String, default: '' },
    color: {
      type: String,
      default: 'green',
      validator: (val: string): boolean => ['green'].includes(val)
    }
  },

  setup(_, { emit }) {
    function onClick(event) {
      emit('update:modelValue', event.target.checked);
      emit('toggle', event.target.checked);
    }

    return { onClick };
  }
});
