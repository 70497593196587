export const MAINNET_GOALS = {
  ClickPoolsTableRow: 'NISBIX8B',
  ClickHeroConnectWallet: 'EIWJBKAR',
  ClickHeroLearnMore: 'HCF8QUTE',
  ClickNavConnectWallet: 'ZIFARDWY',
  ClickNavTrade: 'JKBUPMVR',
  ClickNavInvest: 'XDOFAMCI',
  ClickNavLogo: 'OT9IBNCJ',
  ClickInvestSettings: 'ZNU2DISP',
  ClickTradeSettings: '1M2HMAYJ',
  ClickInvest: 'BRT10IGE',
  ClickWithdraw: 'P5GBF31M',
  Invested: 'KN2G0QUT',
  Withdrawal: 'X4NINEBD',
  ConnectedWallet: '2QHQLTRF',
  ClickSwap: 'OGZ8QPE8',
  Swapped: 'VYZUMRKD'
};

export const POLYGON_GOALS = {
  ClickPoolsTableRow: '5RT8Q9KZ',
  ClickHeroConnectWallet: 'I8P8QBXP',
  ClickHeroLearnMore: 'ICVACFRJ',
  ClickNavConnectWallet: 'HZQOCQ1G',
  ClickNavTrade: 'CGQREIWO',
  ClickNavInvest: 'ENSNQJPP',
  ClickNavLogo: 'IQPSAOJ5',
  ClickInvestSettings: 'VN3WDRQD',
  ClickTradeSettings: 'E6TS9Z3I',
  ClickInvest: 'GHXZHXM9',
  ClickWithdraw: 'GVMC8LT9',
  Invested: 'LFVDQ2JU',
  Withdrawal: 'AIWFJX9X',
  ConnectedWallet: 'NG87QMMZ',
  ClickSwap: 'MJFXHNG6',
  Swapped: 'I7LE2TCF'
};

export const GOALS_MAP = {
  '1': MAINNET_GOALS,
  '137': POLYGON_GOALS
};
