// import { reactive } from 'vue';
import { useQuery } from 'vue-query';
// import { UseQueryOptions } from 'react-query/types';
import { blockSubgraphClient } from '@/services/balancer/subgraph/block-subraph.client';

/**
 * TYPES
 */
/**
 * Fetches all allowances for given tokens for each provided contract address.
 */

// options: UseQueryOptions<number> = {} これが引数だった
export default function useAverageBlockTimeQuery() {
  const queryFn = async () => {
    console.log('Fetching average block time is 1.286');

    return await blockSubgraphClient.getAverageBlockTime();
  };

  // const queryOptions = reactive({
  //   ...options
  // });

  // const sampleVal = "1.286";
  return useQuery<number>(['AverageBlockTime'], queryFn);
}
