
import { defineComponent, toRefs, computed, ref } from 'vue';

import useNumbers from '@/composables/useNumbers';

import TradePairToggle from '@/components/cards/TradeCard/TradePairToggle.vue';
import SelectTokenModal from '@/components/modals/SelectTokenModal/SelectTokenModal.vue';
import useTokens from '@/composables/useTokens';
import { NATIVE_ASSET_ADDRESS } from '@/constants/tokens';
import TradeEthBufferModal from '@/components/cards/TradeCard/TradeEthBufferModal.vue';

const ETH_BUFFER = 0.3;
const ETH_MODAL_MESSAGE_BUFFER = 1;

export default defineComponent({
  components: {
    TradePairToggle,
    SelectTokenModal,
    TradeEthBufferModal
  },
  props: {
    tokenInAmountInput: {
      type: String,
      required: true
    },
    tokenInAddressInput: {
      type: String,
      required: true
    },
    tokenOutAmountInput: {
      type: String,
      required: true
    },
    tokenOutAddressInput: {
      type: String,
      required: true
    },
    exactIn: {
      type: Boolean,
      required: true
    },
    priceImpact: {
      type: Number,
      required: true
    }
  },
  emits: [
    'tokenInAddressChange',
    'tokenInAmountChange',
    'tokenOutAddressChange',
    'tokenOutAmountChange',
    'exactInChange',
    'change'
  ],
  setup(props, { emit }) {
    const { tokens, balances, injectTokens } = useTokens();
    const { fNum, toFiat } = useNumbers();

    const {
      tokenInAmountInput,
      tokenInAddressInput,
      tokenOutAmountInput,
      tokenOutAddressInput,
      exactIn
    } = toRefs(props);

    const tokenInValue = computed(() =>
      toFiat(tokenInAmountInput.value, tokenInAddressInput.value)
    );

    const tokenInSymbol = computed(() => {
      const tokenIn = tokens.value[tokenInAddressInput.value];
      const symbol = tokenIn ? tokenIn.symbol : '';
      return symbol;
    });

    const tokenOutValue = computed(() =>
      toFiat(tokenOutAmountInput.value, tokenOutAddressInput.value)
    );

    const tokenOutSymbol = computed(() => {
      const tokenOut = tokens.value[tokenOutAddressInput.value];
      const symbol = tokenOut ? tokenOut.symbol : '';
      return symbol;
    });

    const tokenInDecimals = computed(() => {
      const decimals = tokens.value[tokenInAddressInput.value]
        ? tokens.value[tokenInAddressInput.value].decimals
        : 18;
      return decimals;
    });

    const tokenOutDecimals = computed(() => {
      const decimals = tokens.value[tokenOutAddressInput.value]
        ? tokens.value[tokenOutAddressInput.value].decimals
        : 18;
      return decimals;
    });

    const isInRate = ref(true);
    const modalSelectTokenType = ref('input');
    const modalSelectTokenIsOpen = ref(false);
    const modalEthBufferIsOpen = ref(false);

    function handleMax(): void {
      const balance = balances.value[tokenInAddressInput.value] || '0';
      const balanceNumber = parseFloat(balance);
      const maxAmount =
        tokenInAddressInput.value !== NATIVE_ASSET_ADDRESS
          ? balance
          : balanceNumber > ETH_BUFFER
          ? (balanceNumber - ETH_BUFFER).toString()
          : '0';
      handleInAmountChange(maxAmount);

      if (
        tokenInAddressInput.value === NATIVE_ASSET_ADDRESS &&
        balanceNumber < ETH_MODAL_MESSAGE_BUFFER
      ) {
        modalEthBufferIsOpen.value = true;
      }
    }

    function handleInAmountChange(value: string): void {
      emit('exactInChange', true);
      emit('tokenInAmountChange', value);
      emit('change', value);
    }

    function handleOutAmountChange(value: string): void {
      emit('exactInChange', false);
      emit('tokenOutAmountChange', value);
      emit('change', value);
    }

    function handleSwitchTokens(): void {
      emit('exactInChange', !exactIn.value);
      emit('tokenInAmountChange', tokenOutAmountInput.value);
      emit('tokenInAddressChange', tokenOutAddressInput.value);
      emit('tokenOutAmountChange', tokenInAmountInput.value);
      emit('tokenOutAddressChange', tokenInAddressInput.value);
    }

    function handleSelectToken(address: string): void {
      if (modalSelectTokenType.value === 'input') {
        if (address === tokenOutAddressInput.value) {
          handleSwitchTokens();
          return;
        } else emit('tokenInAddressChange', address);
      } else {
        if (address === tokenInAddressInput.value) {
          handleSwitchTokens();
          return;
        } else emit('tokenOutAddressChange', address);
      }
      injectTokens([address]);
    }

    const rateMessage = computed(() => {
      const tokenIn = tokens.value[tokenInAddressInput.value];
      const tokenOut = tokens.value[tokenOutAddressInput.value];
      if (!tokenIn || !tokenOut) {
        return '';
      }
      const tokenInAmount = parseFloat(tokenInAmountInput.value);
      const tokenOutAmount = parseFloat(tokenOutAmountInput.value);
      if (!tokenInAmount || !tokenOutAmount) {
        return '';
      }
      if (isInRate.value) {
        const rate = tokenOutAmount / tokenInAmount;
        const message = `1 ${tokenIn.symbol} = ${fNum(rate, 'token')} ${
          tokenOut.symbol
        }`;
        return message;
      } else {
        const rate = tokenInAmount / tokenOutAmount;
        const message = `1 ${tokenOut.symbol} = ${fNum(rate, 'token')} ${
          tokenIn.symbol
        }`;
        return message;
      }
    });

    function toggleRate(): void {
      isInRate.value = !isInRate.value;
    }

    function openModalSelectToken(type: string): void {
      modalSelectTokenIsOpen.value = true;
      modalSelectTokenType.value = type;
    }

    const balanceLabel = computed(
      () => balances.value[tokenInAddressInput.value]
    );

    return {
      fNum,
      handleMax,
      balanceLabel,
      handleInAmountChange,
      handleOutAmountChange,
      handleSwitchTokens,
      rateMessage,
      toggleRate,
      tokenInValue,
      tokenInSymbol,
      tokenOutValue,
      tokenOutSymbol,
      modalSelectTokenIsOpen,
      openModalSelectToken,
      handleSelectToken,
      tokenInDecimals,
      tokenOutDecimals,
      modalEthBufferIsOpen
    };
  }
});
