import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("button", {
      ref: "activator",
      onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
      onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
      class: [{ 'cursor-default': _ctx.disabled }]
    }, [
      _renderSlot(_ctx.$slots, "activator", {}, () => [
        _createVNode(_component_BalIcon, {
          name: "info",
          size: "md",
          class: "text-gray-400"
        })
      ])
    ], 34),
    _createVNode("div", _mergeProps({
      ref: "content",
      class: ["tooltip text-sm bg-white dark:bg-gray-800 font-medium shadow rounded-md border dark:border-gray-900 z-50 text-white", _ctx.tooltipClasses]
    }, _ctx.$attrs), [
      _renderSlot(_ctx.$slots, "default")
    ], 16)
  ], 64))
}