import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNavActivityBtn = _resolveComponent("AppNavActivityBtn")
  const _component_AppNavClaimBtn = _resolveComponent("AppNavClaimBtn")
  const _component_AppNavAccountBtn = _resolveComponent("AppNavAccountBtn")
  const _component_WalletIcon = _resolveComponent("WalletIcon")
  const _component_BalBtn = _resolveComponent("BalBtn")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.account)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_AppNavActivityBtn),
          _createVNode(_component_AppNavClaimBtn),
          _createVNode(_component_AppNavAccountBtn)
        ]))
      : (_openBlock(), _createBlock(_component_BalBtn, {
          key: 1,
          color: "white",
          size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
          onClick: _ctx.toggleWalletSelectModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_WalletIcon, { class: "mr-2" }),
            _createVNode("span", {
              class: "hidden lg:inline-block",
              textContent: _toDisplayString(_ctx.$t('connectWallet'))
            }, null, 8, ["textContent"]),
            _createVNode("span", {
              class: "lg:hidden",
              textContent: _toDisplayString(_ctx.$t('connect'))
            }, null, 8, ["textContent"])
          ]),
          _: 1
        }, 8, ["size", "onClick"]))
  ]))
}