import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "absolute right-4 top-2 float-right flex flex-col items-end hidden md:block" }
const _hoisted_2 = {
  key: 0,
  class: "text-green-500 font-semibold text-right"
}
const _hoisted_3 = {
  key: 1,
  class: "text-red-500 font-semibold text-right"
}
const _hoisted_4 = {
  key: 2,
  class: "font-semibold text-right"
}
const _hoisted_5 = {
  key: 3,
  class: "font-semibold text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.tvl > 0 && _ctx.beetsPrice > 0)
      ? (_openBlock(), _createBlock("div", _hoisted_2, " TVL: $" + _toDisplayString(_ctx.fNum(_ctx.tvl, 'usd_lg')), 1))
      : _createCommentVNode("", true),
    (_ctx.beetsPrice > 0)
      ? (_openBlock(), _createBlock("div", _hoisted_3, " BEETS: " + _toDisplayString(_ctx.fNum(_ctx.beetsPrice, 'usd')), 1))
      : _createCommentVNode("", true),
    (_ctx.beetsPrice > 0 && _ctx.marketCap > 0)
      ? (_openBlock(), _createBlock("div", _hoisted_4, " Market Cap: $" + _toDisplayString(_ctx.fNum(_ctx.marketCap, 'usd_lg')), 1))
      : _createCommentVNode("", true),
    (_ctx.beetsPrice > 0 && _ctx.circulatingSupply > 0)
      ? (_openBlock(), _createBlock("div", _hoisted_5, " Circulating: " + _toDisplayString(_ctx.fNum(_ctx.circulatingSupply, 'token_lg')), 1))
      : _createCommentVNode("", true)
  ]))
}