
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  toRef,
  toRefs,
  watch
} from 'vue';
import { FormRef } from '@/types';
import {
  isLessThanOrEqualTo,
  isPositive,
  isRequired
} from '@/lib/utils/validations';
import { useI18n } from 'vue-i18n';
import { scale } from '@/lib/utils';
import { DecoratedPoolWithRequiredFarm } from '@/services/balancer/subgraph/types';
import useFathom from '@/composables/useFathom';

import { TOKENS } from '@/constants/tokens';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import useFarm from '@/composables/farms/useFarm';
import useFarmUserQuery from '@/composables/queries/useFarmUserQuery';
import { BigNumber } from 'bignumber.js';
import useEthers from '@/composables/useEthers';

type DataProps = {
  withdrawForm: FormRef;
  amount: string;
  propMax: string[];
  validInput: boolean;
  propToken: number;
};

export default defineComponent({
  name: 'FarmWithdrawForm',
  components: {},
  emits: ['success'],

  props: {
    pool: {
      type: Object as PropType<DecoratedPoolWithRequiredFarm>,
      required: true
    }
  },

  setup(props, { emit }) {
    const data = reactive<DataProps>({
      withdrawForm: {} as FormRef,
      amount: '',
      propMax: [],
      validInput: true,
      propToken: 0
    });

    const { txListener } = useEthers();
    const { isWalletReady, account, toggleWalletSelectModal } = useWeb3();
    const withdrawing = ref(false);
    const { t } = useI18n();
    const { tokens } = useTokens();
    const { trackGoal, Goals } = useFathom();
    const { amount } = toRefs(data);
    const { withdrawAndHarvest } = useFarm(toRef(props, 'pool'));
    const farmUserQuery = useFarmUserQuery(props.pool.farm.id);
    const farmUser = computed(() => {
      return farmUserQuery.data.value;
    });
    const bptDeposited = computed(() => {
      const amount = farmUser.value?.amount;

      return amount ? scale(new BigNumber(amount), -18).toString() : '0';
    });

    function amountRules() {
      return isWalletReady.value && farmUser.value
        ? [
            isPositive(),
            isLessThanOrEqualTo(Number(bptDeposited.value), t('exceedsBalance'))
          ]
        : [isPositive()];
    }

    async function submit(): Promise<void> {
      if (!data.withdrawForm.validate()) return;

      withdrawing.value = true;
      const amountScaled = scale(new BigNumber(amount.value), 18);
      const tx = await withdrawAndHarvest(amountScaled);

      if (!tx) {
        withdrawing.value = false;
        return;
      }

      txListener(tx, {
        onTxConfirmed: async () => {
          emit('success', tx);
          amount.value = '';
          withdrawing.value = false;
        },
        onTxFailed: () => {
          withdrawing.value = false;
        }
      });
    }

    /*watch(balances, (newBalances, oldBalances) => {
      const balancesChanged = !isEqual(newBalances, oldBalances);
      if (balancesChanged) {
        //
      }
    });*/

    watch(isWalletReady, isAuth => {
      if (!isAuth) {
        data.amount = '0';
        data.propMax = [];
      }
    });

    watch(account, () => {
      /*if (hasZeroBalance.value) {
        //
      } else {
        //
      }*/
    });

    onMounted(() => {
      /*if (hasZeroBalance.value) {
        //
      } else {
        //
      }*/
    });

    return {
      // data
      ...toRefs(data),
      withdrawing,

      Goals,
      TOKENS,
      // computed
      tokens,
      amountRules,
      isWalletReady,
      toggleWalletSelectModal,
      isRequired,
      // methods
      submit,
      trackGoal,
      farmUser,
      loading: withdrawing,
      bptDeposited
    };
  }
});
