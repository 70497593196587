
import { defineComponent, PropType, ref } from 'vue';
import SuccessOverlay from '@/components/cards/SuccessOverlay.vue';
import { useI18n } from 'vue-i18n';
import useFathom from '@/composables/useFathom';
import useWeb3 from '@/services/web3/useWeb3';
import FarmDepositForm from '@/components/forms/farm_actions/FarmDepositForm.vue';
import { DecoratedPoolWithRequiredFarm } from '@/services/balancer/subgraph/types';
import FarmWithdrawForm from '@/components/forms/farm_actions/FarmWithdrawForm.vue';

export default defineComponent({
  name: 'FarmActionsCard',

  emits: ['onTx'],

  components: {
    FarmWithdrawForm,
    FarmDepositForm,
    SuccessOverlay
  },

  props: {
    pool: {
      type: Object as PropType<DecoratedPoolWithRequiredFarm>,
      required: true
    },
    missingPrices: { type: Boolean, default: false }
  },

  setup(_, { emit }) {
    /**
     * COMPOSABLES
     */
    const { t } = useI18n();
    const { trackGoal, Goals } = useFathom();
    const { explorerLinks: explorer } = useWeb3();

    /**
     * STATE
     */
    const tabs = [
      { value: 'deposit', label: 'Deposit' },
      { value: 'withdraw', label: t('withdraw') }
    ];
    const activeTab = ref(tabs[0].value);
    const investmentSuccess = ref(false);
    const withdrawalSuccess = ref(false);
    const txHash = ref('');

    /**
     * METHODS
     */
    function handleInvestment(txReceipt): void {
      investmentSuccess.value = true;
      txHash.value = txReceipt.hash;
      trackGoal(Goals.Invested);
      emit('onTx', txReceipt);
    }

    function handleWithdrawal(txReceipt): void {
      withdrawalSuccess.value = true;
      txHash.value = txReceipt.hash;
      trackGoal(Goals.Withdrawal);
      emit('onTx', txReceipt);
    }

    return {
      // data
      activeTab,
      tabs,
      investmentSuccess,
      withdrawalSuccess,
      txHash,
      // methods
      handleInvestment,
      handleWithdrawal,
      explorer
    };
  }
});
