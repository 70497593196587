
import useFathom from '@/composables/useFathom';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { EXTERNAL_LINKS } from '@/constants/links';
import useApp from '@/composables/useApp';
import useWeb3 from '@/services/web3/useWeb3';

export default defineComponent({
  name: 'AppNavToggle',
  components: {},
  props: {
    darkModeBg: { type: String, default: '800' }
  },

  setup() {
    const route = useRoute();
    const activeClasses =
      'bg-black text-white rounded-lg dark:text-black dark:bg-white';
    const isTradePage = computed(() => route.name === 'trade');
    const isFarmPage = computed(() => String(route.name).startsWith('farm'));
    const isBeetsPage = computed(() => route.name === 'beets');
    const isPortfolioPage = computed(() => route.name === 'my-portfolio');
    const isInvestPage = computed(
      () => route.name === 'invest' || String(route.name).startsWith('pool')
    );
    const isFreshBeetsPage = computed(() => route.name === 'fbeets');
    const isHomePage = computed(
      () =>
        !isTradePage.value &&
        !isFarmPage.value &&
        !isBeetsPage.value &&
        !isInvestPage.value
    );
    const { trackGoal, Goals } = useFathom();

    const { appLoading } = useApp();
    const { account, isLoadingProfile } = useWeb3();

    const isLoggedIn = computed(
      () => !appLoading.value && !isLoadingProfile.value && !!account.value
    );

    return {
      isTradePage,
      isFarmPage,
      activeClasses,
      trackGoal,
      isBeetsPage,
      isInvestPage,
      isHomePage,
      Goals,
      EXTERNAL_LINKS,
      isLoggedIn,
      isPortfolioPage,
      isFreshBeetsPage
    };
  }
});
