import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  class: "rounded-br-xl h-4 w-4 flex bg-yellow-500 absolute top-0 left-0 bg-opacity-80"
}
const _hoisted_4 = {
  key: 1,
  class: "px-6 py-4 relative"
}
const _hoisted_5 = {
  key: 0,
  class: "px-6 py-4"
}
const _hoisted_6 = { class: "mb-2 text-left" }
const _hoisted_7 = { class: "px-6 py-4 -mt-1 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")
  const _component_TokenPills = _resolveComponent("TokenPills")
  const _component_LiquidityMiningTooltip = _resolveComponent("LiquidityMiningTooltip")
  const _component_BalTable = _resolveComponent("BalTable")
  const _component_BalCard = _resolveComponent("BalCard")

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    class: "mt-4",
    square: _ctx.upToLargeBreakpoint,
    noBorder: _ctx.upToLargeBreakpoint,
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: _ctx.columns,
        data: _ctx.data,
        "is-loading": _ctx.isLoading,
        "is-loading-more": _ctx.isLoadingMore,
        "skeleton-class": "h-64",
        sticky: "both",
        square: _ctx.upToLargeBreakpoint,
        link: {
        to: 'pool',
        getParams: pool => ({ id: pool.id })
      },
        "on-row-click": _ctx.handleRowClick,
        "is-paginated": _ctx.isPaginated,
        onLoadMore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('loadMore'))),
        "initial-state": {
        sortColumn: 'poolValue',
        sortDirection: 'desc'
      }
      }, {
        iconColumnHeader: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            (_ctx.darkMode)
              ? (_openBlock(), _createBlock("img", {
                  key: 0,
                  src: require('@/assets/images/icons/tokens_white.svg')
                }, null, 8, ["src"]))
              : (_openBlock(), _createBlock("img", {
                  key: 1,
                  src: require('@/assets/images/icons/tokens_black.svg')
                }, null, 8, ["src"]))
          ])
        ]),
        iconColumnCell: _withCtx((pool) => [
          _createVNode("div", _hoisted_2, [
            (pool.farm && parseFloat(pool.shares) > 0)
              ? (_openBlock(), _createBlock("div", _hoisted_3))
              : _createCommentVNode("", true),
            (!_ctx.isLoading)
              ? (_openBlock(), _createBlock("div", _hoisted_4, [
                  _createVNode(_component_BalAssetSet, {
                    addresses: _ctx.orderedTokenAddressesFor(pool),
                    width: 100
                  }, null, 8, ["addresses"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        poolNameCell: _withCtx((pool) => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock("div", _hoisted_5, [
                _createVNode("h5", _hoisted_6, _toDisplayString(pool.name), 1),
                _createVNode(_component_TokenPills, {
                  tokens: _ctx.orderedPoolTokens(pool),
                  isStablePool: _ctx.isStableLike(pool)
                }, null, 8, ["tokens", "isStablePool"])
              ]))
            : _createCommentVNode("", true)
        ]),
        aprCell: _withCtx((pool) => [
          _createVNode("div", _hoisted_7, [
            _createTextVNode(_toDisplayString(Number(pool.dynamic.apr.pool) > 10000
              ? '-'
              : _ctx.fNum(pool.dynamic.apr.total, 'percent')) + " ", 1),
            _createVNode(_component_LiquidityMiningTooltip, { pool: pool }, null, 8, ["pool"])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "is-loading", "is-loading-more", "square", "link", "on-row-click", "is-paginated"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}