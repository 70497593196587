import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bal-icon inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("i", {
      "data-feather": _ctx.name,
      width: _ctx.iconSize,
      height: _ctx.iconSize,
      fill: _ctx.fill
    }, null, 8, ["data-feather", "width", "height", "fill"])
  ]))
}