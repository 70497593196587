
import { computed, defineComponent } from 'vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useWeb3 from '@/services/web3/useWeb3';
import useTransactions from '@/composables/useTransactions';

import ActivityCounter from './ActivityCounter.vue';
import ActivityRows from './ActivityRows.vue';

export default defineComponent({
  name: 'AppNavActivityBtn',

  components: {
    ActivityCounter,
    ActivityRows
  },

  setup() {
    // COMPOSABLES
    const { upToLargeBreakpoint } = useBreakpoints();
    const { isLoadingProfile, profile, account } = useWeb3();
    const {
      transactions,
      pendingTransactions,
      getExplorerLink,
      clearAllTransactions,
      isSuccessfulTransaction
    } = useTransactions();

    // COMPUTED
    const unconfirmedTransactions = computed(() =>
      transactions.value.filter(({ status }) => status !== 'confirmed')
    );

    const confirmedTransactions = computed(() =>
      transactions.value.filter(({ status }) => status === 'confirmed')
    );

    return {
      // methods
      clearAllTransactions,
      getExplorerLink,
      isSuccessfulTransaction,

      // computed
      account,
      profile,
      upToLargeBreakpoint,
      isLoadingProfile,
      transactions,
      pendingTransactions,
      unconfirmedTransactions,
      confirmedTransactions
    };
  }
});
