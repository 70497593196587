import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "chart mr-n2 ml-n2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")
  const _component_BalLbpLineChart = _resolveComponent("BalLbpLineChart")

  return (_ctx.loading || _ctx.appLoading)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-96"
      }))
    : (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_BalLbpLineChart, {
          data: _ctx.series,
          isPeriodSelectionEnabled: false,
          axisLabelFormatter: { yAxis: '$0.000', xAxis: 'datetime' },
          color: _ctx.chartColors,
          legendState: {},
          height: "96",
          showLegend: true
        }, null, 8, ["data", "axisLabelFormatter", "color"])
      ]))
}