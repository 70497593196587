
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import * as PoolPageComponents from '@/components/pages/pool';
import { useQueryClient } from 'vue-query';
import useNumbers from '@/composables/useNumbers';
import usePoolQuery from '@/composables/queries/usePoolQuery';
import { EXTERNAL_LINKS } from '@/constants/links';
import useWeb3 from '@/services/web3/useWeb3';
import useApp from '@/composables/useApp';
import BeetsLBPChart from '@/components/pages/lbp/BeetsLBPChart.vue';
import BeetsLBPStatCards from '@/components/pages/lbp/BeetsLBPStatCards.vue';
import LBPTradeCard from '@/components/cards/LBPTradeCard/LBPTradeCard.vue';
import LBPTable from '@/components/tables/LBPTable/LBPTable.vue';
import useSubgraphTokenPricesQuery from '@/composables/queries/useSubgraphTokenPricesQuery';
import {
  POOLS_ROOT_KEY,
  SWAPS_ROOT_KEY,
  TOKEN_PRICES_ROOT_KEY
} from '@/constants/queryKeys';
import useTokenLists from '@/composables/useTokenLists';
import {
  format,
  isAfter,
  isBefore,
  parseISO,
  getUnixTime,
  subDays
} from 'date-fns';

interface LbpPageData {
  refetchQueriesOnBlockNumber: number;
}

const REFETCH_QUERIES_BLOCK_BUFFER = 3;

export default defineComponent({
  components: {
    ...PoolPageComponents,
    BeetsLBPChart,
    BeetsLBPStatCards,
    LBPTradeCard,
    LBPTable
  },

  setup() {
    /**
     * COMPOSABLES
     */
    const { appLoading } = useApp();
    const { fNum } = useNumbers();
    const { appNetworkConfig } = useWeb3();
    const queryClient = useQueryClient();
    const { blockNumber } = useWeb3();
    const { loadingTokenLists } = useTokenLists();
    const lbpConfig = appNetworkConfig.lbp;

    const isLbpOver = ref(isAfter(new Date(), parseISO(lbpConfig.endTime)));
    const isBeforeLbpStart = ref(
      isBefore(new Date(), parseISO(lbpConfig.startTime))
    );

    const poolQuery = usePoolQuery(lbpConfig.poolId);
    const loadingPool = computed(
      () =>
        poolQuery.isLoading.value ||
        poolQuery.isIdle.value ||
        poolQuery.error.value
    );
    const pool = computed(() => {
      return poolQuery.data.value;
    });
    const enabled = computed(() => !!pool.value?.id);
    const swapEnabled = computed(() => poolQuery.data.value?.swapEnabled);

    const tokenPricesQuery = useSubgraphTokenPricesQuery(
      ref(lbpConfig.poolId),
      ref(lbpConfig.tokenAddress.toLowerCase()),
      ref(`${getUnixTime(subDays(new Date(), 1))}`)
    );
    const tokenPrices = computed(() => tokenPricesQuery.data.value || []);
    const loadingTokenPrices = computed(
      () =>
        tokenPricesQuery.isLoading.value ||
        tokenPricesQuery.isIdle.value ||
        tokenPricesQuery.error.value
    );

    const data = reactive<LbpPageData>({ refetchQueriesOnBlockNumber: 0 });

    const startDateTimeFormatted = computed(() =>
      format(parseISO(lbpConfig.startTime), 'MMM d, HH:mm')
    );

    /**
     * METHODS
     */
    function onNewTx(): void {
      isLbpOver.value = isAfter(new Date(), parseISO(lbpConfig.endTime));
      isBeforeLbpStart.value = isBefore(
        new Date(),
        parseISO(lbpConfig.startTime)
      );

      queryClient.invalidateQueries([POOLS_ROOT_KEY]);
      queryClient.invalidateQueries([
        POOLS_ROOT_KEY,
        'current',
        lbpConfig.poolId
      ]);
      queryClient.invalidateQueries([SWAPS_ROOT_KEY]);
      queryClient.invalidateQueries([TOKEN_PRICES_ROOT_KEY]);
      data.refetchQueriesOnBlockNumber =
        blockNumber.value + REFETCH_QUERIES_BLOCK_BUFFER;
    }

    /**
     * WATCHERS
     */
    watch(blockNumber, () => {
      isLbpOver.value = isAfter(new Date(), parseISO(lbpConfig.endTime));
      isBeforeLbpStart.value = isBefore(
        new Date(),
        parseISO(lbpConfig.startTime)
      );

      if (data.refetchQueriesOnBlockNumber === blockNumber.value) {
        queryClient.invalidateQueries([POOLS_ROOT_KEY]);
        queryClient.invalidateQueries([
          POOLS_ROOT_KEY,
          'current',
          lbpConfig.poolId
        ]);
        queryClient.invalidateQueries([SWAPS_ROOT_KEY]);
        queryClient.invalidateQueries([TOKEN_PRICES_ROOT_KEY]);
      } else {
        poolQuery.refetch.value();
        tokenPricesQuery.refetch.value();
      }
    });

    function handleLbpStateChange() {
      isLbpOver.value = isAfter(new Date(), parseISO(lbpConfig.endTime));
      isBeforeLbpStart.value = isBefore(
        new Date(),
        parseISO(lbpConfig.startTime)
      );
    }

    return {
      EXTERNAL_LINKS,
      appLoading,
      fNum,
      pool,
      enabled,
      lbpPoolId: lbpConfig.poolId,
      lbpTokenAddress: lbpConfig.tokenAddress.toLowerCase(),
      lbpTokenName: lbpConfig.tokenSymbol,
      lbpTokenStartingAmount: lbpConfig.startingAmount,
      lbpStartTime: lbpConfig.startTime,
      lbpEndTime: lbpConfig.endTime,
      lbpWeightStep: lbpConfig.weightStep,
      lbpTimeStep: lbpConfig.timeStep,
      usdcAddress: lbpConfig.usdcAddress.toLowerCase(),
      lbpTokenAddressFormatted: lbpConfig.tokenAddress,
      usdcAddressFormatted: lbpConfig.usdcAddress,
      loadingPool,
      tokenPrices,
      loadingTokenPrices,
      loadingTokenLists,
      swapEnabled,
      isLbpOver,
      onNewTx,
      isBeforeLbpStart,
      startDateTimeFormatted,
      handleLbpStateChange
    };
  }
});
