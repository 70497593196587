import { renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalCard = _resolveComponent("BalCard")
  const _directive_click_outside = _resolveDirective("click-outside")

  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: "bal-popover-activator group",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.trigger === 'click' && _ctx.togglePopover())),
      onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.trigger === 'hover' && _ctx.showPopover())),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.trigger === 'hover' && _ctx.hidePopover()))
    }, [
      _renderSlot(_ctx.$slots, "activator")
    ], 32),
    _createVNode("div", { class: _ctx.popoverWrapperClasses }, [
      _createVNode(_component_BalCard, _mergeProps({ shadow: "lg" }, _ctx.$attrs, { darkBgColor: "800" }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 16)
    ], 2)
  ], 512)), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}