import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")
  const _component_BalBtn = _resolveComponent("BalBtn")
  const _component_AppNavSettings = _resolveComponent("AppNavSettings")
  const _component_BalPopover = _resolveComponent("BalPopover")

  return (_openBlock(), _createBlock(_component_BalPopover, { "no-pad": "" }, {
    activator: _withCtx(() => [
      _createVNode(_component_BalBtn, {
        class: ["text-base", { btn: _ctx.upToLargeBreakpoint }],
        loading: _ctx.isLoadingProfile,
        "loading-label": _ctx.upToLargeBreakpoint ? '' : _ctx.$t('connecting'),
        color: "white",
        size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
        circle: _ctx.upToLargeBreakpoint
      }, {
        default: _withCtx(() => [
          (_ctx.nftImage !== null)
            ? (_openBlock(), _createBlock("img", {
                key: 0,
                src: _ctx.nftImage,
                width: "22",
                class: "rounded-full h-22 w-22"
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock(_component_Avatar, {
                key: 1,
                address: _ctx.account,
                profile: _ctx.profile,
                size: _ctx.avatarSize
              }, null, 8, ["address", "profile", "size"])),
          (_ctx.profile.ens)
            ? (_openBlock(), _createBlock("span", {
                key: 2,
                textContent: _toDisplayString(_ctx.profile.ens),
                class: "pl-2 hidden lg:inline-block"
              }, null, 8, ["textContent"]))
            : (_openBlock(), _createBlock("span", {
                key: 3,
                textContent: _toDisplayString(_ctx._shorten(_ctx.account)),
                class: "pl-2 hidden lg:inline-block eth-address"
              }, null, 8, ["textContent"]))
        ]),
        _: 1
      }, 8, ["class", "loading", "loading-label", "size", "circle"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_AppNavSettings)
    ]),
    _: 1
  }))
}