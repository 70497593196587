import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mt-2 mr-2 flex items-center px-2 h-10 bg-gray-50 dark:bg-gray-700 rounded-lg relative" }
const _hoisted_2 = {
  key: 0,
  class: "w-3 h-3 rounded-full border-2 border-white dark:border-gray-850 group-hover:border-gray-50 dark:group-hover:border-gray-800 bg-green-200 dark:bg-green-500 absolute top-0 right-0 -mt-1 -mr-1"
}
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = { class: "font-medium text-gray-400 text-xs mt-px ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAsset = _resolveComponent("BalAsset")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.hasBalance)
      ? (_openBlock(), _createBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_BalAsset, {
      address: _ctx.address,
      size: 24
    }, null, 8, ["address"]),
    _createVNode("span", _hoisted_3, _toDisplayString(_ctx.symbol), 1),
    _createVNode("span", _hoisted_4, _toDisplayString(_ctx.weight), 1)
  ]))
}