import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 border-t dark:border-gray-900" }
const _hoisted_2 = { class: "border dark:border-gray-900 rounded-lg shadow-inner p-2" }
const _hoisted_3 = { class: "flex items-center justify-between mb-3 text-sm text-gray-600" }
const _hoisted_4 = { class: "flex items-end" }
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { class: "flex items-center w-1/2" }
const _hoisted_7 = { class: "flex flex-col w-3/4 leading-none" }
const _hoisted_8 = { class: "flex flex-col w-1/2 leading-none text-right pl-2" }
const _hoisted_9 = {
  key: 0,
  class: "text-xs text-gray-400"
}
const _hoisted_10 = { class: "flex items-center h-full w-24" }
const _hoisted_11 = { class: "flex flex-col ml-3" }
const _hoisted_12 = { class: "font-medium text-sm leading-none w-14 truncate" }
const _hoisted_13 = {
  key: 0,
  class: "leading-none text-xs mt-1 text-gray-500"
}
const _hoisted_14 = { class: "p-2" }
const _hoisted_15 = {
  key: 0,
  class: "flex items-center mb-4"
}
const _hoisted_16 = {
  key: 1,
  class: "flex items-center mb-4"
}
const _hoisted_17 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTypeToggle = _resolveComponent("FormTypeToggle")
  const _component_BalRangeInput = _resolveComponent("BalRangeInput")
  const _component_BalAsset = _resolveComponent("BalAsset")
  const _component_BalBtn = _resolveComponent("BalBtn")
  const _component_BalTextInput = _resolveComponent("BalTextInput")
  const _component_router_link = _resolveComponent("router-link")
  const _component_BalIcon = _resolveComponent("BalIcon")
  const _component_BalTooltip = _resolveComponent("BalTooltip")
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")
  const _component_BalAlert = _resolveComponent("BalAlert")
  const _component_BalForm = _resolveComponent("BalForm")

  return (_openBlock(), _createBlock(_component_BalForm, {
    ref: "investForm",
    onOnSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormTypeToggle, {
        modelValue: _ctx.investType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.investType = $event)),
        "form-types": _ctx.formTypes,
        "missing-prices": _ctx.missingPrices,
        "has-zero-balance": _ctx.hasZeroBalance,
        loading: _ctx.loading
      }, null, 8, ["modelValue", "form-types", "missing-prices", "has-zero-balance", "loading"]),
      (_ctx.isProportional)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            _createVNode("div", _hoisted_1, [
              _createVNode("div", _hoisted_2, [
                _createVNode("div", _hoisted_3, [
                  _createVNode("span", {
                    textContent: _toDisplayString(_ctx.$t('amountToInvest'))
                  }, null, 8, ["textContent"]),
                  _createVNode("span", null, _toDisplayString(_ctx.propPercentage) + "%", 1)
                ]),
                _createVNode("div", _hoisted_4, [
                  _createVNode("span", {
                    class: "mr-2 text-lg font-medium w-1/2 break-words leading-none",
                    title: _ctx.total
                  }, _toDisplayString(_ctx.missingPrices ? '-' : _ctx.total), 9, ["title"]),
                  _createVNode(_component_BalRangeInput, {
                    class: "w-1/2",
                    modelValue: _ctx.range,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.range = $event)),
                    max: 1000,
                    interval: 1,
                    min: 0,
                    tooltip: "none",
                    disabled: _ctx.loading
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ]),
            _createVNode("div", {
              class: [
          'px-4 py-3 bg-gray-50 dark:bg-gray-850 border-b dark:border-gray-900',
          _ctx.hasZeroBalance ? '' : 'border-t'
        ]
            }, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pool.tokenAddresses, (token, i) => {
                return (_openBlock(), _createBlock("div", {
                  key: token,
                  class: "py-3 last:mb-0"
                }, [
                  _createVNode("div", _hoisted_5, [
                    _createVNode("div", _hoisted_6, [
                      _createVNode(_component_BalAsset, {
                        address: token,
                        class: "mr-2"
                      }, null, 8, ["address"]),
                      _createVNode("div", _hoisted_7, [
                        _createVNode("span", {
                          class: "break-words",
                          title: 
                    `${_ctx.fNum(_ctx.amounts[i], 'token')} ${
                      _ctx.pool.onchain.tokens[token].symbol
                    }`
                  
                        }, _toDisplayString(_ctx.fNum(_ctx.amounts[i], 'token')) + " " + _toDisplayString(_ctx.pool.onchain.tokens[token].symbol), 9, ["title"]),
                        _createVNode("span", {
                          class: "text-xs text-gray-400 break-words",
                          title: `${_ctx.formatBalance(i)} balance`
                        }, _toDisplayString(_ctx.$t('balance')) + ": " + _toDisplayString(_ctx.formatBalance(i)), 9, ["title"])
                      ])
                    ]),
                    _createVNode("div", _hoisted_8, [
                      _createVNode("span", {
                        class: "break-words",
                        title: _ctx.fNum(_ctx.amountUSD(i), 'usd')
                      }, _toDisplayString(_ctx.amountUSD(i) === 0 ? '-' : _ctx.fNum(_ctx.amountUSD(i), 'usd')), 9, ["title"]),
                      (!_ctx.isStableLikePool)
                        ? (_openBlock(), _createBlock("span", _hoisted_9, _toDisplayString(_ctx.fNum(_ctx.tokenWeights[i], 'percent_lg')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128))
            ], 2)
          ], 64))
        : (_openBlock(), _createBlock("div", {
            key: 1,
            class: [
        'px-4 pt-6 border-b dark:border-gray-900',
        _ctx.hasZeroBalance ? '' : 'border-t'
      ]
          }, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pool.tokenAddresses, (token, i) => {
              return (_openBlock(), _createBlock(_component_BalTextInput, {
                key: token,
                name: token,
                modelValue: _ctx.amounts[i],
                "onUpdate:modelValue": ($event: any) => (_ctx.amounts[i] = $event),
                isValid: _ctx.validInputs[i],
                "onUpdate:isValid": ($event: any) => (_ctx.validInputs[i] = $event),
                rules: _ctx.amountRules(i),
                type: "number",
                min: "0",
                step: "any",
                placeholder: "0",
                "decimal-limit": _ctx.tokenDecimals(i),
                disabled: _ctx.loading,
                "validate-on": "input",
                "prepend-border": "",
                "append-shadow": ""
              }, {
                prepend: _withCtx(() => [
                  _createVNode("div", _hoisted_10, [
                    _createVNode(_component_BalAsset, { address: token }, null, 8, ["address"]),
                    _createVNode("div", _hoisted_11, [
                      _createVNode("span", _hoisted_12, _toDisplayString(_ctx.pool.onchain.tokens[token].symbol), 1),
                      (!_ctx.isStableLikePool)
                        ? (_openBlock(), _createBlock("span", _hoisted_13, _toDisplayString(_ctx.fNum(_ctx.tokenWeights[i], 'percent_lg')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                info: _withCtx(() => [
                  _createVNode("div", {
                    class: "cursor-pointer",
                    onClick: _withModifiers(($event: any) => (_ctx.amounts[i] = _ctx.tokenBalance(i).toString()), ["prevent"])
                  }, _toDisplayString(_ctx.$t('balance')) + ": " + _toDisplayString(_ctx.formatBalance(i)), 9, ["onClick"])
                ]),
                append: _withCtx(() => [
                  _createVNode("div", _hoisted_14, [
                    _createVNode(_component_BalBtn, {
                      size: "xs",
                      color: "white",
                      onClick: _withModifiers(($event: any) => (_ctx.amounts[i] = _ctx.tokenBalance(i).toString()), ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('max')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 2
              }, 1032, ["name", "modelValue", "onUpdate:modelValue", "isValid", "onUpdate:isValid", "rules", "decimal-limit", "disabled"]))
            }), 128)),
            (_ctx.isWethPool)
              ? (_openBlock(), _createBlock("div", _hoisted_15, [
                  _createVNode(_component_router_link, {
                    to: {
            name: 'trade',
            params: {
              assetIn: _ctx.TOKENS.AddressMap[_ctx.appNetworkConfig.key].ETH,
              assetOut: _ctx.TOKENS.AddressMap[_ctx.appNetworkConfig.key].WETH
            }
          },
                    class: "text-xs text-gray-500 dark:text-white underline"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('wrapInstruction', [_ctx.nativeAsset, `W${_ctx.nativeAsset}`])), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  _createVNode(_component_BalTooltip, null, {
                    activator: _withCtx(() => [
                      _createVNode(_component_BalIcon, {
                        name: "info",
                        size: "xs",
                        class: "text-gray-400 dark:text-white ml-2"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode("div", {
                        class: "w-52",
                        innerHTML: _ctx.$t('ethBufferInstruction')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.isWstETHPool)
              ? (_openBlock(), _createBlock("div", _hoisted_16, [
                  _createVNode(_component_router_link, {
                    to: {
            name: 'trade',
            params: {
              assetIn: _ctx.appNetworkConfig.addresses.stETH,
              assetOut: _ctx.appNetworkConfig.addresses.wstETH
            }
          },
                    class: "text-xs text-gray-500 underline"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('wrapInstruction', [
              _ctx.symbolFor(_ctx.appNetworkConfig.addresses.stETH),
              _ctx.symbolFor(_ctx.appNetworkConfig.addresses.wstETH)
            ])), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  _createVNode(_component_BalTooltip, null, {
                    activator: _withCtx(() => [
                      _createVNode(_component_BalIcon, {
                        name: "info",
                        size: "xs",
                        class: "text-gray-400 ml-2"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode("div", {
                        class: "w-52",
                        innerHTML: _ctx.$t('wrapStEthTooltip')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ], 2)),
      _createVNode("div", _hoisted_17, [
        (!_ctx.isWalletReady)
          ? (_openBlock(), _createBlock(_component_BalBtn, {
              key: 0,
              label: _ctx.$t('connectWallet'),
              block: "",
              onClick: _withModifiers(_ctx.toggleWalletSelectModal, ["prevent"])
            }, null, 8, ["label", "onClick"]))
          : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
              _createVNode("div", {
                class: ['flex items-center text-sm mb-4', _ctx.priceImpactClasses],
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _createVNode("span", null, _toDisplayString(_ctx.$t('priceImpact')) + ": " + _toDisplayString(_ctx.fNum(_ctx.priceImpact, 'percent')), 1),
                _createVNode(_component_BalTooltip, null, {
                  activator: _withCtx(() => [
                    (_ctx.priceImpact >= 0.01)
                      ? (_openBlock(), _createBlock(_component_BalIcon, {
                          key: 0,
                          name: "alert-triangle",
                          size: "xs",
                          class: "ml-1"
                        }))
                      : (_openBlock(), _createBlock(_component_BalIcon, {
                          key: 1,
                          name: "info",
                          size: "xs",
                          class: "text-gray-400 -mb-px ml-2"
                        }))
                  ]),
                  default: _withCtx(() => [
                    _createVNode("div", {
                      innerHTML: _ctx.$t('customAmountsTip'),
                      class: "w-52"
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                })
              ], 2),
              (_ctx.requireApproval)
                ? (_openBlock(), _createBlock(_component_BalBtn, {
                    key: 0,
                    label: `${_ctx.$t('approve')} ${_ctx.symbolFor(_ctx.requiredAllowances[0])}`,
                    loading: _ctx.approving,
                    "loading-label": _ctx.$t('approving'),
                    disabled: !_ctx.hasAmounts || !_ctx.hasValidInputs,
                    block: "",
                    onClick: _withModifiers(_ctx.approveAllowances, ["prevent"])
                  }, null, 8, ["label", "loading", "loading-label", "disabled", "onClick"]))
                : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                    (_ctx.priceImpact >= 0.01)
                      ? (_openBlock(), _createBlock(_component_BalCheckbox, {
                          key: 0,
                          modelValue: _ctx.highPiAccepted,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.highPiAccepted = $event)),
                          rules: [_ctx.isRequired(this.$t('priceImpactCheckbox'))],
                          name: "highPiAccepted",
                          class: "text-gray-500 mb-12",
                          size: "sm",
                          label: _ctx.$t('priceImpactAccept')
                        }, null, 8, ["modelValue", "rules", "label"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_BalBtn, {
                      type: "submit",
                      "loading-label": _ctx.$t('confirming'),
                      color: "gradient",
                      disabled: !_ctx.hasAmounts || !_ctx.hasValidInputs,
                      loading: _ctx.loading,
                      block: "",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.trackGoal(_ctx.Goals.ClickInvest)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('invest')) + " " + _toDisplayString(_ctx.missingPrices || _ctx.total.length > 15 ? '' : _ctx.total), 1)
                      ]),
                      _: 1
                    }, 8, ["loading-label", "disabled", "loading"]),
                    (_ctx.hasUnstakedBpt)
                      ? (_openBlock(), _createBlock(_component_BalAlert, {
                          key: 1,
                          title: "You have unstaked BPT in your wallet",
                          description: "If you deposit your BPT into the farm, you will earn additional rewards paid out in BEETS.",
                          type: "warning",
                          size: "sm",
                          class: "mt-4"
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["onOnSubmit"]))
}