
import { computed, defineComponent, ref } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';
import { fNum } from '@/composables/useNumbers';
import { useGovernance } from '@/composables/useGovernance';
import { bn, fp } from '@/lib/utils/numbers';

export default defineComponent({
  components: {},

  setup() {
    const { isWalletReady } = useWeb3();
    const {
      totalVestedTokenAmount,
      fBeetsTotalSupply,
      fBeetsBalance,
      enter,
      leave,
      exchangeAmount,
      exchangeRate,
      approveVestingToken
    } = useGovernance();

    const currentExchangeRate = ref();
    const currentExchangeAmount = ref();
    const currentFBeetsBalance = ref();

    totalVestedTokenAmount().then(result => {
      console.log('total vested amount: ', result.toString());
    });

    fBeetsTotalSupply().then(result => {
      console.log('total fbeets supply', result);
    });

    exchangeAmount().then(amount => {
      currentExchangeAmount.value = amount;
      console.log('exchange amount: ', amount);
    });

    exchangeRate().then(rate => {
      currentExchangeRate.value = rate;
      console.log('exchange rate: ', rate);
    });

    fBeetsBalance().then(balance => {
      currentFBeetsBalance.value = balance;
      console.log('my fBeets balance', balance);
    });

    async function approve100() {
      await approveVestingToken(fp(100));
      console.log('approved 100');
    }

    async function enterFbeets() {
      await enter(fp(100));
      console.log('entered with 100 bpt');
    }

    async function leaveFbeets() {
      await leave(fp(100));
      console.log('left with 100 bpt');
    }

    return {
      fNum,
      enterFbeets,
      leaveFbeets,
      approve100,
      currentExchangeRate,
      currentExchangeAmount,
      currentFBeetsBalance
    };
  }
});
