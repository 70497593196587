
import { isRequired } from '@/lib/utils/validations';
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

import useTokenApproval from '@/composables/trade/useTokenApproval';
import useValidation, {
  TradeValidation
} from '@/composables/trade/useValidation';
import useSor from '@/composables/trade/useSor';

import SuccessOverlay from '@/components/cards/SuccessOverlay.vue';
import TradePreviewModal from '@/components/modals/TradePreviewModal.vue';
import TradeRoute from '@/components/cards/TradeCard/TradeRoute.vue';
import TradeSettingsPopover, {
  TradeSettingsContext
} from '@/components/popovers/TradeSettingsPopover.vue';
import { useI18n } from 'vue-i18n';
import useWeb3 from '@/services/web3/useWeb3';
import useBreakpoints from '@/composables/useBreakpoints';
import useTokens from '@/composables/useTokens';
import useDarkMode from '@/composables/useDarkMode';
import { configService } from '@/services/config/config.service';

import { getWrapAction, WrapType } from '@/lib/utils/balancer/wrapper';
import LbpTradePair from '@/components/cards/LBPTradeCard/LBPTradePair.vue';

const { nativeAsset } = configService.network;

export default defineComponent({
  components: {
    SuccessOverlay,
    LbpTradePair,
    TradePreviewModal,
    TradeRoute,
    TradeSettingsPopover
  },

  emits: ['onTx'],

  props: {
    usdcAddress: { type: String, required: true },
    lbpTokenName: { type: String, required: true },
    lbpTokenAddress: { type: String, required: true },
    swapEnabled: { type: Boolean }
  },

  setup(props, { emit }) {
    const highPiAccepted = ref(false);
    const store = useStore();
    const { explorerLinks } = useWeb3();
    const { t } = useI18n();
    const { bp } = useBreakpoints();

    const { tokens } = useTokens();
    const { userNetworkConfig } = useWeb3();
    const { darkMode } = useDarkMode();

    const exactIn = ref(true);
    const tokenInAddress = ref('');
    const tokenInAmount = ref('');
    const tokenOutAddress = ref('');
    const tokenOutAmount = ref('');
    const tradeSuccess = ref(false);
    const txHash = ref('');
    const modalTradePreviewIsOpen = ref(false);

    const slippageBufferRate = computed(() =>
      parseFloat(store.state.app.slippage)
    );

    const tokenIn = computed(() => tokens.value[tokenInAddress.value]);

    const tokenOut = computed(() => tokens.value[tokenOutAddress.value]);

    const liquiditySelection = computed(() => store.state.app.tradeLiquidity);

    const tradeCardShadow = computed(() => {
      switch (bp.value) {
        case 'xs':
          return 'none';
        case 'sm':
          return 'lg';
        default:
          return 'xl';
      }
    });

    const wrapType = computed(() => {
      return getWrapAction(tokenInAddress.value, tokenOutAddress.value);
    });
    const isWrap = computed(() => wrapType.value === WrapType.Wrap);
    const isUnwrap = computed(() => wrapType.value === WrapType.Unwrap);

    const isHighPriceImpact = computed(() => {
      return priceImpact.value >= 0.05 && !highPiAccepted.value;
    });

    const tradeDisabled = computed(() => {
      if (errorMessage.value !== TradeValidation.VALID) return true;
      if (isHighPriceImpact.value) return true;
      return false;
    });

    // COMPOSABLES
    const { isLoading: isLoadingApprovals } = useTokenApproval(
      tokenInAddress,
      tokenInAmount,
      tokens
    );
    const {
      trading,
      trade,
      initSor,
      handleAmountChange,
      priceImpact,
      sorReturn,
      latestTxHash,
      latestTx,
      pools,
      fetchPools,
      poolsLoading,
      slippageError
    } = useSor({
      exactIn,
      tokenInAddressInput: tokenInAddress,
      tokenInAmountInput: tokenInAmount,
      tokenOutAddressInput: tokenOutAddress,
      tokenOutAmountInput: tokenOutAmount,
      tokens,
      wrapType,
      tokenIn,
      tokenOut,
      slippageBufferRate
    });
    const { errorMessage } = useValidation(
      tokenInAddress,
      tokenInAmount,
      tokenOutAddress,
      tokenOutAmount
    );

    const title = computed(() => {
      if (isWrap.value) return t('wrap');
      if (isUnwrap.value) return t('unwrap');
      return t('trade');
    });

    const error = computed(() => {
      console.log('swap enabled', props.swapEnabled);
      if (props.swapEnabled === false) {
        return {
          header: 'Swapping disabled',
          body: 'Swapping is disabled for this Event.'
        };
      }

      if (isHighPriceImpact.value) {
        return {
          header: t('highPriceImpact'),
          body: t('highPriceImpactDetailed'),
          label: t('accept')
        };
      }
      switch (errorMessage.value) {
        case TradeValidation.NO_NATIVE_ASSET:
          return {
            header: t('noNativeAsset', [nativeAsset.symbol]),
            body: t('noNativeAssetDetailed', [
              nativeAsset.symbol,
              configService.network.chainName
            ])
          };
        case TradeValidation.NO_BALANCE:
          return {
            header: t('insufficientBalance'),
            body: t('insufficientBalanceDetailed')
          };
        case TradeValidation.NO_LIQUIDITY:
          return {
            header: t('insufficientLiquidity'),
            body: t('insufficientLiquidityDetailed')
          };
        default:
          return undefined;
      }
    });

    function handleErrorButtonClick() {
      if (isHighPriceImpact.value) {
        highPiAccepted.value = true;
      }
    }

    async function populateInitialTokens(): Promise<void> {
      tokenInAddress.value = props.usdcAddress;
      tokenOutAddress.value = props.lbpTokenAddress;
    }

    function showTradePreviewModal() {
      slippageError.value = false;
      modalTradePreviewIsOpen.value = true;
    }

    watch(userNetworkConfig, async () => {
      await initSor();
      await handleAmountChange();
    });

    watch(tokenInAddress, () => {
      store.commit('trade/setInputAsset', tokenInAddress.value);
      handleAmountChange();
    });

    watch(tokenOutAddress, () => {
      store.commit('trade/setOutputAsset', tokenOutAddress.value);
      handleAmountChange();
    });

    watch(liquiditySelection, () => {
      // When the liquidity type is changed we need to update the trade to use that selection
      handleAmountChange();
    });

    watch(latestTxHash, () => {
      // Refresh SOR pools
      fetchPools();
      txHash.value = latestTxHash.value;
      tradeSuccess.value = true;
      modalTradePreviewIsOpen.value = false;

      emit('onTx', latestTx.value);
    });

    populateInitialTokens();

    return {
      highPiAccepted,
      title,
      error,
      handleErrorButtonClick,
      tokenInAddress,
      tokenInAmount,
      tokenOutAddress,
      tokenOutAmount,
      exactIn,
      handleAmountChange,
      errorMessage,
      sorReturn,
      pools,
      trading,
      trade,
      txHash,
      modalTradePreviewIsOpen,
      tradeSuccess,
      priceImpact,
      isRequired,
      tradeDisabled,
      TradeSettingsContext,
      poolsLoading,
      showTradePreviewModal,
      isLoadingApprovals,
      bp,
      darkMode,
      tradeCardShadow,
      explorer: explorerLinks,
      slippageError
    };
  }
});
