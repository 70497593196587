import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-center",
  style: {"width":"70%"}
}
const _hoisted_2 = { class: "text-gray-700 dark:text-white text-base" }
const _hoisted_3 = { class: "capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    class: "wallet-connect-btn",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connectWallet(_ctx.wallet)))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("img", {
        src: require(`@/assets/images/connectors/${_ctx.wallet}.svg`),
        class: "h-10 w-10 mr-4"
      }, null, 8, ["src"]),
      _createVNode("h5", _hoisted_2, [
        _createVNode("span", _hoisted_3, _toDisplayString(_ctx.WalletNameMap[_ctx.wallet]), 1)
      ])
    ])
  ]))
}