
import { computed, defineComponent } from 'vue';

import useNumbers from '@/composables/useNumbers';
import useProtocolDataQuery from '@/composables/queries/useProtocolDataQuery';

export default defineComponent({
  name: 'GlobalStats',

  setup() {
    const protocolDataQuery = useProtocolDataQuery();
    const { fNum } = useNumbers();
    const tvl = computed(
      () => protocolDataQuery.data?.value?.totalLiquidity || 0
    );

    const beetsPrice = computed(
      () => protocolDataQuery.data?.value?.beetsPrice || 0
    );
    const circulatingSupply = computed(
      () => protocolDataQuery.data.value?.circulatingSupply || 0
    );
    const marketCap = computed(() => {
      return beetsPrice.value * circulatingSupply.value;
    });

    return {
      tvl,
      beetsPrice,
      circulatingSupply,
      marketCap,
      fNum
    };
  }
});
