import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "-mx-4 p-4 items-center" }
const _hoisted_2 = { class: "mx-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")
  const _component_BalModal = _resolveComponent("BalModal")

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    onClose: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, " You only have " + _toDisplayString(_ctx.nativeAssetBalance) + " " + _toDisplayString(_ctx.nativeAssetSymbol) + " in your wallet. Remember to save enough for gas. ", 1),
        _createVNode(_component_BalBtn, {
          class: "mt-8",
          label: "Ok",
          block: "",
          onClick: _withModifiers(_ctx.onClose, ["prevent"])
        }, null, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}