
import useNumbers from '@/composables/useNumbers';
import { PoolToken } from '@/services/balancer/subgraph/types';
import { defineComponent, PropType } from 'vue';
import useTokens from '@/composables/useTokens';
import WeightedTokenPill from './WeightedTokenPill.vue';
import StableTokenPill from './StableTokenPill.vue';

export default defineComponent({
  name: 'TokenPills',

  components: {
    WeightedTokenPill,
    StableTokenPill
  },

  props: {
    tokens: {
      type: Array as PropType<PoolToken[]>,
      required: true
    },
    isStablePool: { type: Boolean, required: true }
  },

  setup() {
    /**
     * COMPOSABLES
     */
    const { fNum } = useNumbers();
    const { tokens, hasBalance } = useTokens();

    /**
     * METHODS
     */
    function symbolFor(token: PoolToken): string {
      return tokens.value[token.address]?.symbol || '---';
    }

    function weightFor(token: PoolToken): string {
      return fNum(token.weight, 'percent_lg');
    }

    return {
      // methods
      symbolFor,
      weightFor,
      hasBalance
    };
  }
});
