import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "text-right flex flex-col" }
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = { class: "px-6 py-4" }
const _hoisted_5 = { class: "px-6 py-4" }
const _hoisted_6 = {
  key: 0,
  class: "px-6 py-4 text-right flex flex-col"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "px-6 py-4 text-right flex flex-col"
}
const _hoisted_9 = { class: "text-right flex flex-col" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "mt-2 text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")
  const _component_TokenPills = _resolveComponent("TokenPills")
  const _component_BalTable = _resolveComponent("BalTable")
  const _component_BalCard = _resolveComponent("BalCard")

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    class: "mt-4",
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        sticky: "both",
        columns: _ctx.columns,
        data: _ctx.data,
        "is-loading": _ctx.isLoading,
        "skeleton-class": "h-64",
        link: {
        to: 'pool',
        getParams: pool => ({ id: pool.id })
      },
        "initial-state": { sortDirection: 'desc', sortColumn: _ctx.latestWeek }
      }, _createSlots({
        iconColumnHeader: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            (_ctx.darkMode)
              ? (_openBlock(), _createBlock("img", {
                  key: 0,
                  src: require('@/assets/images/icons/tokens_white.svg')
                }, null, 8, ["src"]))
              : (_openBlock(), _createBlock("img", {
                  key: 1,
                  src: require('@/assets/images/icons/tokens_black.svg')
                }, null, 8, ["src"]))
          ])
        ]),
        iconColumnCell: _withCtx((pool) => [
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_BalAssetSet, {
              addresses: _ctx.orderedTokenAddressesFor(pool),
              width: 100
            }, null, 8, ["addresses"])
          ])
        ]),
        poolNameCell: _withCtx((pool) => [
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_TokenPills, {
              tokens: _ctx.orderedPoolTokens(pool),
              isStablePool: _ctx.isStableLike(pool)
            }, null, 8, ["tokens", "isStablePool"])
          ])
        ]),
        _: 2
      }, [
        _renderList(_ctx.weeks, (week, i) => {
          return {
            name: `header-${week.week}`,
            fn: _withCtx(() => [
              _createVNode("div", _hoisted_2, [
                _createVNode("span", null, _toDisplayString(_ctx.getWeekName(week.week)), 1),
                _createVNode("span", _hoisted_3, "Starts " + _toDisplayString(_ctx.getWeekStart(i)), 1)
              ])
            ])
          }
        }),
        _renderList(_ctx.weeks, (week, i) => {
          return {
            name: week.week,
            fn: _withCtx((pool) => [
              (pool.distributions[i].distribution)
                ? (_openBlock(), _createBlock("div", _hoisted_6, [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(pool.distributions[i]
              .distribution, (tokenDist, tokenIndex) => {
                      return (_openBlock(), _createBlock("span", {
                        key: tokenDist.tokenAddress
                      }, [
                        (tokenIndex !== 0)
                          ? (_openBlock(), _createBlock("span", _hoisted_7, "+"))
                          : _createCommentVNode("", true),
                        _createTextVNode("  " + _toDisplayString(_ctx.fNum(tokenDist.amount, 'token_lg')) + " " + _toDisplayString(_ctx.tokens[_ctx.getAddress(tokenDist.tokenAddress)]?.symbol || 'N/A'), 1)
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createBlock("div", _hoisted_8, " - "))
            ])
          }
        }),
        _renderList(_ctx.weeks, (week) => {
          return {
            name: `totals-${week.week}`,
            fn: _withCtx(() => [
              _createVNode("div", _hoisted_9, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.totals[week.week], ({ token, total }, i) => {
                  return (_openBlock(), _createBlock("span", {
                    key: `totals-${token}`,
                    class: "font-semibold text-right"
                  }, [
                    (i !== 0)
                      ? (_openBlock(), _createBlock("span", _hoisted_10, "+"))
                      : _createCommentVNode("", true),
                    _createTextVNode("  " + _toDisplayString(_ctx.fNum(total, 'token_lg')) + " " + _toDisplayString(_ctx.tokens[_ctx.getAddress(token)]?.symbol || 'N/A'), 1)
                  ]))
                }), 128)),
                _createVNode("span", _hoisted_11, "~$" + _toDisplayString(_ctx.fNum(_ctx.calculatePricesFor(_ctx.totals[week.week]))), 1)
              ])
            ])
          }
        })
      ]), 1032, ["columns", "data", "is-loading", "link", "initial-state"])
    ]),
    _: 1
  }))
}