import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/investments-headline.svg'
import _imports_1 from '@/assets/images/headline.svg'


const _hoisted_1 = { class: "w-full max-w-3xl mx-auto" }
const _hoisted_2 = { class: "flex justify-center mt-4" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  width: "400"
}
const _hoisted_4 = {
  key: 1,
  class: "text-3xl font-bold text-white"
}
const _hoisted_5 = { class: "flex justify-center mt-2" }
const _hoisted_6 = {
  key: 0,
  src: _imports_1,
  width: "400"
}
const _hoisted_7 = { class: "flex justify-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")
  const _component_BalBtn = _resolveComponent("BalBtn")
  const _component_BalIcon = _resolveComponent("BalIcon")

  return (_openBlock(), _createBlock("div", {
    class: ['app-hero', _ctx.classes]
  }, [
    _createVNode("div", _hoisted_1, [
      (_ctx.isWalletReady)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            _createVNode("div", _hoisted_2, [
              (_ctx.darkMode)
                ? (_openBlock(), _createBlock("img", _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isLoadingUserPools || _ctx.isLoadingDecoratedFarms)
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "h-10 w-40 mx-auto",
                  white: ""
                }))
              : (_openBlock(), _createBlock("span", _hoisted_4, _toDisplayString(_ctx.fNum(_ctx.totalInvestedAndFarmAmount, 'usd', { forcePreset: true })), 1))
          ], 64))
        : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
            _createVNode("div", _hoisted_5, [
              (_ctx.darkMode)
                ? (_openBlock(), _createBlock("img", _hoisted_6))
                : _createCommentVNode("", true)
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode(_component_BalBtn, {
                color: _ctx.darkMode ? 'gray' : 'white',
                class: "mr-3",
                onClick: _ctx.onClickConnect
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('connectWallet')), 1)
                ]),
                _: 1
              }, 8, ["color", "onClick"]),
              _createVNode(_component_BalBtn, {
                tag: "a",
                href: "https://docs.beethovenx.io/",
                target: "_blank",
                rel: "noreferrer",
                color: "white",
                outline: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.trackGoal(_ctx.Goals.ClickHeroLearnMore)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('learnMore')) + " ", 1),
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "ml-1"
                  })
                ]),
                _: 1
              })
            ])
          ], 64))
    ])
  ], 2))
}