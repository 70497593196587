
import { computed, defineComponent } from 'vue';
import LMTable from '@/components/tables/LMTable/LMTable.vue';
import LiquidityMiningDistributions from '@/lib/utils/liquidityMining/MultiTokenLiquidityMining.json';
import usePoolsQuery from '@/composables/queries/usePoolsQuery';
import { flatten, last, takeRight, uniq } from 'lodash';
import { Network } from '@/constants/network';
import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { getAddress } from '@ethersproject/address';
import useConfig from '@/composables/useConfig';

type TokenDistribution = {
  tokenAddress: string;
  amount: number;
};

type PoolDistribution = {
  chainId: number;
  pools: Record<string, TokenDistribution[]>;
};

export type TokenTotal = { token: string; total: number };

type LiquidityMiningDistribution = Record<string, PoolDistribution[]>;

export type WeeklyDistributions = {
  week: string;
  distributions: TokenDistribution[];
};

export default defineComponent({
  components: {
    LMTable
  },
  setup() {
    const { fNum } = useNumbers();
    const { priceFor } = useTokens();
    const { networkConfig } = useConfig();

    // seperate variable to type the JSON
    const weeksJSON = (LiquidityMiningDistributions as unknown) as LiquidityMiningDistribution;

    const totals = computed(() => {
      // map tracking a list of token totals for each week
      const weeklyTotals: Record<string, TokenTotal[]> = {};
      for (const week of weeks) {
        // map tracking totals for each token
        const tokenTotals: Record<string, TokenTotal> = {};
        // this will be an array of pools with their token distributions,
        // we just want the values, not the pool id
        const distributions = Object.values(week.distributions);
        for (const distribution of distributions) {
          for (const allocation of distribution) {
            if (!tokenTotals[allocation.tokenAddress]) {
              tokenTotals[allocation.tokenAddress] = {
                token: allocation.tokenAddress,
                total: allocation.amount
              };
              continue;
            } else {
              tokenTotals[allocation.tokenAddress].total =
                tokenTotals[allocation.tokenAddress].total + allocation.amount;
            }
          }
        }
        weeklyTotals[week.week] = Object.values(tokenTotals);
      }
      return weeklyTotals;
    });

    const currentWeekTotalFiat = computed(() => {
      let totalFiat = 0;
      const currentWeek = last(Object.values(totals.value));
      if (currentWeek) {
        for (const total of currentWeek) {
          const fiatValue = priceFor(getAddress(total.token)) * total.total;
          totalFiat = totalFiat + fiatValue;
        }
      }
      return totalFiat;
    });

    // only concerned with past 3 weeks
    const weeks = takeRight(Object.keys(weeksJSON), 3).map(week => ({
      week: week,
      distributions: weeksJSON[week]
        .filter(d => d.chainId === networkConfig.chainId)
        .map(d => d.pools)[0]
    }));

    const poolIds = computed(() =>
      uniq(flatten(weeks.map(d => Object.keys(d.distributions))))
    );

    // there shouldn't be too many pools for the LM distribution for each chain
    // so we won't need to get a paginated response, just get all
    const {
      data: poolsResponse,
      isLoading: isLoadingPools,
      isIdle: isLoadingPoolsIdle
    } = usePoolsQuery(undefined, {}, { poolIds, pageSize: 1000 });

    const pools = computed(() => poolsResponse.value?.pages);

    const shortNetworkName = computed(() => {
      if (networkConfig.chainId === Network.MAINNET) {
        return 'Ethereum';
      }
      if (networkConfig.chainId === Network.POLYGON) {
        return 'Polygon';
      }
      return 'Unknown Network';
    });

    const description = computed(() => {
      if (networkConfig.chainId === Network.MAINNET) {
        return `BAL distributions on Ethereum can be claimed weekly by tapping the
        liquidity mining claim tool in the header.`;
      }
      if (networkConfig.chainId === Network.POLYGON) {
        return `BAL distributions on Polygon are automatically airdropped to eligible
        addresses weekly.`;
      }
      return '';
    });

    const currentWeek = computed(() => last(last(weeks)?.week.split('_')));
    const otherNetwork = computed(() => {
      if (networkConfig.chainId === Network.MAINNET) return 'Polygon';
      if (networkConfig.chainId === Network.POLYGON) return 'Ethereum';
      return 'Ethereum';
    });

    return {
      weeks,
      pools,
      shortNetworkName,
      totals,
      description,
      isLoadingPools,
      isLoadingPoolsIdle,
      currentWeek,
      currentWeekTotalFiat,
      fNum,
      otherNetwork
    };
  }
});
