export const POOLS = {
  Pagination: {
    PerPage: 75
  },
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [
      '0x8339e311265a025fd5792db800daa8eda4264e2c000200000000000000000029',
      '0x45910faff3cbf990fdb204682e93055506682d1700020000000000000000000d',
      '0xfa22ec1c02f121083bf04fbbcaad019f490d7a3000020000000000000000002a',
      '0xa660ba113f9aabaeb4bcd28a4a1705f4997d5432000200000000000000000022',
      '0xea8886a24b6e01fba88a9e98d794e8d1f29ed863000200000000000000000010',
      '0x0b09dea16768f0799065c475be02919503cb2a3500020000000000000000001a',
      '0xde148e6cc3f6047eed6e97238d341a2b8589e19e000200000000000000000017',
      '0x5d563ca1e2daaae3402c36097b934630ab53702c000200000000000000000024',
      '0x9f1f16b025f703ee985b58ced48daf93dad2f7ef00020000000000000000001e',
      '0x3ebf48cd7586d7a4521ce59e53d9a907ebf1480f000200000000000000000028',
      '0xefaa1604e82e1b3af8430b90192c1b9e8197e377000200000000000000000021',
      '0x9c08c7a7a89cfd671c79eacdc6f07c1996277ed5000200000000000000000025',
      '0xe99481dc77691d8e2456e5f3f61c1810adfc1503000200000000000000000018',
      '0xec60a5fef79a92c741cb74fdd6bfc340c0279b01000200000000000000000015',
      '0x231e687c9961d3a27e6e266ac5c433ce4f8253e4000200000000000000000023',
      '0x80be0c303d8ad2a280878b50a39b1ee8e54dbd2200020000000000000000000f',
      '0x5aa90c7362ea46b3cbfbd7f01ea5ca69c98fef1c000200000000000000000020',
      '0xa6f548df93de924d73be7d25dc02554c6bd66db500020000000000000000000e',
      '0x96646936b91d6b9d7d0c47c496afbf3d6ec7b6f8000200000000000000000019',
      '0x186084ff790c65088ba694df11758fae4943ee9e000200000000000000000013',
      '0x4626d81b3a1711beb79f4cecff2413886d461677000200000000000000000011',
      '0x072f14b85add63488ddad88f855fda4a99d6ac9b000200000000000000000027',
      '0xaac98ee71d4f8a156b6abaa6844cdb7789d086ce00020000000000000000001b',
      '0x7eb878107af0440f9e776f999ce053d277c8aca8000200000000000000000012',
      '0x16faf9f73748013155b7bc116a3008b57332d1e600020000000000000000001c',
      '0x6ae82385f76e3742f89cb46343b169f6ee49de1b000200000000000000000016',
      '0x8a92c3afabab59101b4e2426c82a7ddbb66b545000020000000000000000001f',
      '0x5c6ee304399dbdb9c8ef030ab642b10820db8f56000200000000000000000014',
      '0xf4c0dd9b82da36c07605df83c8a416f11724d88b000200000000000000000026',
      '0x3e5fa9518ea95c3e533eb377c001702a9aacaa32000200000000000000000052',
      '0xf8a0623ab66f985effc1c69d05f1af4badb01b0000020000000000000000001d',
      '0x021c343c6180f03ce9e48fae3ff432309b9af19900020000000000000000000b',
      '0x14462305d211c12a736986f4e8216e28c5ea7ab4000200000000000000000068',
      '0xf5aaf7ee8c39b651cebf5f1f50c10631e78e0ef9000200000000000000000069'
    ]
  },
  BlockList: [
    '0x03b3cc19e4087fd3d63167a604ef8063b095ba16000100000000000000000006'
  ],
  Stable: {
    AllowList: [
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000063',
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56000000000000000000000066',
      '0x9f19a375709baf0e8e35c2c5c65aca676c4c719100000000000000000000006e',
      '0x32296969ef14eb0c6d29669c550d4a0449130230000200000000000000000080', // Lido Metastable
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000012', // polygon
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc5600020000000000000000001e', // polgon
      '0x6b15a01b5d46a5321b627bd7deef1af57bc629070000000000000000000000d4', // kovan
      '0xe08590bde837eb9b2d42aa1196469d6e08fe96ec000200000000000000000101', //kovan
      '0xf4f5a8fa35d00d2273e1466c2e29a417a3464e3c00020000000000000000000e',
      '0x33276d43ada054a281d40a11d48310cdc0156fc2000200000000000000000001', // rinkebyここわからん
      '0xd41bf724b6e31311db582c5388af6b316e812fe4000200000000000000000003', //fantom
      '0xd163415bd34ef06f57c58d2aed5a5478afb464cc00000000000000000000000e', //fantom mim
      '0x443804efc861616b920bc30d49282ae72276b5df000000000000000000000010', //fantom
      '0x2ed55f12e700cde978e1f0491d681919814ca44b000200000000000000000021',
      '0x2c580c6f08044d6dfaca8976a66c8fadddbd9901000000000000000000000038',
      '0x8fe1a318e67bc203180adf68007613546e8af393000000000000000000000046',
      '0x30a92a4eeca857445f41e4bb836e64d66920f1c0000200000000000000000071',
      '0x6ddf83e473956b6062e6660ee47639ecd7f9d558000200000000000000000070 ' 
    ]
  },
  Factories: {
    '0xa5bf2ddf098bb0ef6d120c98217dd6b141c74ee0': 'oracleWeightedPool',
    '0x8e9aa87e45e92bad84d5f8dd1bff34fb92637de9': 'weightedPool',
    '0xc66ba2b6595d3613ccab350c886ace23866ede24': 'stablePool',
    '0x67d27634e44793fe63c467035e31ea8635117cd4': 'stablePool', // Metastable
    '0x751dfdace1ad995ff13c927f6f761c6604532c79': 'stablePool', // Kovan
    '0x590e544e7ca956bb878f8c873e82e65550d67d2f': 'stablePool', // Kovan Metastable
    '0x72f700b6e33ab2db1b915e06b99d7ab913748380': 'weightedPool', //rinkeby
    '0xf2955e1663a281e7cff697a6f0213ad09914f3df': 'weightedPool', //rinkeby 2 token
    '0x73bf10fb8867f5687d0c7590d0ae2b4bebcc85fd': 'stablePool', //rinkeby
    '0xf63193af8114855c872bc85ed9a10dd66fc9a290': 'stablePool', //rinkeby metastable

    '0x60467cb225092ce0c989361934311175f437cf53': 'weightedPool', //fantom
    '0x92b377187bccc6556fced2f1e6dad65850c20630': 'weightedPool', //fantom 2 token
    '0x55df810876354fc3e249f701dd78dede57991f8d': 'stablePool', //fantom
    '0x70b55af71b29c5ca7e67bd1995250364c4be5554': 'stablePool' //fantom metastable
  }
};
