import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")
  const _component_BalBtn = _resolveComponent("BalBtn")

  return (_openBlock(), _createBlock("div", {
    class: ['bal-alert', _ctx.classes]
  }, [
    _createVNode("div", {
      class: ['bal-alert-container', _ctx.containerClasses]
    }, [
      _createVNode("div", {
        class: ['bal-alert-icon', _ctx.iconClasses]
      }, [
        _createVNode(_component_BalIcon, {
          name: "alert-circle",
          size: _ctx.iconSize
        }, null, 8, ["size"])
      ], 2),
      _createVNode("div", {
        class: ['bal-alert-content', _ctx.contentClasses]
      }, [
        _createVNode("div", _hoisted_1, [
          _createVNode("h5", {
            class: ['bal-alert-title', _ctx.titleClasses, _ctx.textSizeClass]
          }, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ])
          ], 2),
          (_ctx.$slots.default || _ctx.description)
            ? (_openBlock(), _createBlock("p", {
                key: 0,
                class: ['bal-alert-description', _ctx.descriptionColor, _ctx.textSizeClass]
              }, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.description), 1)
                ])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        (_ctx.actionLabel)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: [_ctx.actionClasses]
            }, [
              _createVNode(_component_BalBtn, {
                color: _ctx.btnColor,
                size: "xs",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('actionClick')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.actionLabel), 1)
                ]),
                _: 1
              }, 8, ["color"])
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}