
import { defineComponent, ref, computed, PropType, watch } from 'vue';

type PopoverTrigger = 'click' | 'hover';

export default defineComponent({
  name: 'BalPopover',

  props: {
    trigger: {
      type: String as PropType<PopoverTrigger>,
      default: 'click'
    },
    align: { type: String, default: 'right' }
  },
  emits: ['show', 'hide'],
  setup(props, { emit }) {
    // DATA
    const popoverOpened = ref(false);

    // METHODS
    function togglePopover() {
      popoverOpened.value = !popoverOpened.value;
    }

    function showPopover() {
      popoverOpened.value = true;
    }

    function hidePopover() {
      popoverOpened.value = false;
    }

    function handleClickOutside() {
      if (props.trigger === 'click') {
        hidePopover();
      }
    }

    // COMPUTED
    const popoverWrapperClasses = computed(() => ({
      'bal-popover-wrapper': true,
      'bal-popover-wrapper-visible': popoverOpened.value,
      [`${props.align}-0`]: true
    }));

    watch(popoverOpened, () => {
      if (popoverOpened.value) {
        emit('show');
      } else {
        emit('hide');
      }
    });

    return {
      // methods
      togglePopover,
      showPopover,
      hidePopover,
      handleClickOutside,

      // computed
      popoverWrapperClasses
    };
  }
});
