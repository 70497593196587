
import { defineComponent } from 'vue';
import BalAsset from '@/components/_global/BalAsset/BalAsset.vue';

export default defineComponent({
  name: 'StableTokenPill',

  components: {
    BalAsset
  },

  props: {
    hasBalance: { type: Boolean, default: false },
    symbol: { type: String, required: true },
    address: { type: String, required: true },
    isLast: { type: Boolean, default: false }
  }
});
