import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "-mt-1 flex flex-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "bg-gray-100 dark:bg-gray-700 rounded-lg flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StableTokenPill = _resolveComponent("StableTokenPill")
  const _component_WeightedTokenPill = _resolveComponent("WeightedTokenPill")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.isStablePool)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tokens, (token, i) => {
            return (_openBlock(), _createBlock(_component_StableTokenPill, {
              key: token,
              hasBalance: _ctx.hasBalance(token.address),
              symbol: _ctx.symbolFor(token),
              address: token.address,
              isLast: _ctx.tokens.length - 1 === i
            }, null, 8, ["hasBalance", "symbol", "address", "isLast"]))
          }), 128))
        ]))
      : (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.tokens, (token) => {
          return (_openBlock(), _createBlock(_component_WeightedTokenPill, {
            key: token.address,
            hasBalance: _ctx.hasBalance(token.address),
            symbol: _ctx.symbolFor(token),
            weight: _ctx.weightFor(token),
            address: token.address
          }, null, 8, ["hasBalance", "symbol", "weight", "address"]))
        }), 128))
  ]))
}