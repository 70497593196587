import { createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "relative ml-4 flex-col" }
const _hoisted_3 = {
  key: 1,
  class: "bal-checkbox-error"
}
const _hoisted_4 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ['bal-checkbox', _ctx.wrapperClasses]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", null, [
        _createVNode("input", {
          type: "checkbox",
          name: _ctx.name,
          checked: _ctx.modelValue,
          class: ['bal-checkbox-input', _ctx.inputClasses],
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 42, ["name", "checked"])
      ]),
      _createVNode("div", _hoisted_2, [
        (_ctx.$slots.label || _ctx.label)
          ? (_openBlock(), _createBlock("label", {
              key: 0,
              for: _ctx.name,
              class: ['bal-checkbox-label', _ctx.labelClasses]
            }, [
              _renderSlot(_ctx.$slots, "label", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ])
            ], 10, ["for"]))
          : _createCommentVNode("", true),
        (_ctx.hasError)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, _toDisplayString(_ctx.errors[0]), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}