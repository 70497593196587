import { createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inline-flex items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("input", {
      type: "radio",
      value: _ctx.value,
      checked: _ctx.modelValue === _ctx.value,
      name: _ctx.name,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange(_ctx.value))),
      class: ['bal-radio-input', _ctx.inputClasses],
      disabled: _ctx.disabled
    }, null, 42, ["value", "checked", "name", "disabled"]),
    (_ctx.$slots.label || _ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: _ctx.name,
          class: ['bal-radio-label', _ctx.labelClasses],
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onChange(_ctx.value)), ["prevent"]))
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 10, ["for"]))
      : _createCommentVNode("", true)
  ]))
}