
import { computed, defineComponent } from 'vue';

import useNumbers from '@/composables/useNumbers';
import usePools from '@/composables/pools/usePools';

import { EXTERNAL_LINKS } from '@/constants/links';
import useFathom from '@/composables/useFathom';
import useWeb3 from '@/services/web3/useWeb3';
import useDarkMode from '@/composables/useDarkMode';
import { sumBy } from 'lodash';

export default defineComponent({
  name: 'AppHero',

  setup() {
    // COMPOSABLES
    const { fNum } = useNumbers();
    const { isWalletReady, toggleWalletSelectModal } = useWeb3();
    const { trackGoal, Goals } = useFathom();
    const {
      totalInvestedAmount,
      isLoadingUserPools,
      onlyPoolsWithFarms
    } = usePools();
    const { darkMode } = useDarkMode();
    //const { decoratedFarms, isLoadingDecoratedFarms } = useDecoratedFarms();

    const classes = computed(() => ({
      ['h-72']: !isWalletReady.value,
      //['h-0']: isWalletReady.value
      ['h-40']: isWalletReady.value
    }));

    const totalInvestedAndFarmAmount = computed(() => {
      return (
        sumBy(onlyPoolsWithFarms.value, pool => pool.farm.stake || 0) +
        parseFloat(totalInvestedAmount.value || '0')
      );
    });

    function onClickConnect() {
      toggleWalletSelectModal(true);
      trackGoal(Goals.ClickHeroConnectWallet);
    }

    return {
      // data
      totalInvestedAmount,
      totalInvestedAndFarmAmount,
      isLoadingUserPools,
      isLoadingDecoratedFarms: false,
      Goals,

      // computed
      isWalletReady,
      classes,
      darkMode,

      // methods
      toggleWalletSelectModal,
      fNum,
      onClickConnect,
      trackGoal,
      // constants
      EXTERNAL_LINKS
    };
  }
});
