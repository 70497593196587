
import { computed, defineComponent } from 'vue';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import { NATIVE_ASSET_ADDRESS } from '@/constants/tokens';
import numeral from 'numeral';

export default defineComponent({
  emits: ['close'],
  props: {},
  setup(props, { emit }) {
    const { balances } = useTokens();
    const { appNetworkConfig } = useWeb3();

    const nativeAssetSymbol = computed(
      () => appNetworkConfig.nativeAsset.symbol
    );

    const nativeAssetBalance = computed(() =>
      numeral(balances.value[NATIVE_ASSET_ADDRESS]).format('0.[0000]')
    );

    function onClose() {
      emit('close');
    }

    return {
      onClose,
      nativeAssetSymbol,
      nativeAssetBalance
    };
  }
});
