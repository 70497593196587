import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("form", _mergeProps({
    action: _ctx.action,
    method: _ctx.method
  }, _ctx.$attrs, {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('onSubmit')), ["prevent"]))
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, ["action", "method"]))
}