import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-4 border-t dark:border-gray-900" }
const _hoisted_2 = { class: "border dark:border-gray-900 rounded-lg shadow-inner p-2" }
const _hoisted_3 = { class: "flex items-center justify-between mb-3 text-sm text-gray-600" }
const _hoisted_4 = { class: "flex items-end" }
const _hoisted_5 = { class: "mr-2 text-lg font-medium w-1/2 leading-none break-words" }
const _hoisted_6 = { class: "px-4 py-3 bg-gray-50 dark:bg-gray-850 border-t dark:border-gray-900 border-b" }
const _hoisted_7 = { class: "flex items-center justify-between" }
const _hoisted_8 = { class: "w-1/2 flex items-center" }
const _hoisted_9 = { class: "w-3/4 flex flex-col leading-none" }
const _hoisted_10 = { class: "break-words" }
const _hoisted_11 = { class: "text-xs text-gray-400 break-words" }
const _hoisted_12 = { class: "w-1/2 flex flex-col leading-none text-right pl-2" }
const _hoisted_13 = { class: "break-words" }
const _hoisted_14 = {
  key: 0,
  class: "text-xs text-gray-400"
}
const _hoisted_15 = {
  key: 1,
  class: "px-4 pt-6 border-t border-b dark:border-gray-900"
}
const _hoisted_16 = { class: "flex items-center h-full w-24" }
const _hoisted_17 = { class: "flex flex-col ml-3" }
const _hoisted_18 = { class: "font-medium text-sm leading-none w-14 truncate" }
const _hoisted_19 = { class: "p-2" }
const _hoisted_20 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTypeToggle = _resolveComponent("FormTypeToggle")
  const _component_BalRangeInput = _resolveComponent("BalRangeInput")
  const _component_BalAsset = _resolveComponent("BalAsset")
  const _component_BalBtn = _resolveComponent("BalBtn")
  const _component_BalTextInput = _resolveComponent("BalTextInput")
  const _component_BalIcon = _resolveComponent("BalIcon")
  const _component_BalTooltip = _resolveComponent("BalTooltip")
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")
  const _component_BalAlert = _resolveComponent("BalAlert")
  const _component_BalForm = _resolveComponent("BalForm")

  return (_openBlock(), _createBlock(_component_BalForm, {
    ref: "withdrawForm",
    onOnSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormTypeToggle, {
        modelValue: _ctx.withdrawType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.withdrawType = $event)),
        "form-types": _ctx.formTypes,
        loading: _ctx.loading
      }, null, 8, ["modelValue", "form-types", "loading"]),
      (_ctx.isProportional)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            _createVNode("div", _hoisted_1, [
              _createVNode("div", _hoisted_2, [
                _createVNode("div", _hoisted_3, [
                  _createVNode("span", {
                    textContent: _toDisplayString(_ctx.$t('amountToWithdraw'))
                  }, null, 8, ["textContent"]),
                  _createVNode("span", null, _toDisplayString(_ctx.propPercentage) + "%", 1)
                ]),
                _createVNode("div", _hoisted_4, [
                  _createVNode("span", _hoisted_5, _toDisplayString(_ctx.missingPrices ? '-' : _ctx.total), 1),
                  _createVNode(_component_BalRangeInput, {
                    class: "w-1/2",
                    modelValue: _ctx.range,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.range = $event)),
                    max: 1000,
                    interval: 1,
                    min: 0,
                    tooltip: "none",
                    disabled: _ctx.loading
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ])
            ]),
            _createVNode("div", _hoisted_6, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pool.tokenAddresses, (token, i) => {
                return (_openBlock(), _createBlock("div", {
                  key: token,
                  class: "py-3 last:mb-0"
                }, [
                  _createVNode("div", _hoisted_7, [
                    _createVNode("div", _hoisted_8, [
                      _createVNode(_component_BalAsset, {
                        address: token,
                        class: "mr-2"
                      }, null, 8, ["address"]),
                      _createVNode("div", _hoisted_9, [
                        _createVNode("span", _hoisted_10, _toDisplayString(_ctx.fNum(_ctx.amounts[i], 'token')) + " " + _toDisplayString(_ctx.pool.onchain.tokens[token].symbol), 1),
                        _createVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('balance')) + ": " + _toDisplayString(_ctx.formatPropBalance(i)), 1)
                      ])
                    ]),
                    _createVNode("div", _hoisted_12, [
                      _createVNode("span", _hoisted_13, _toDisplayString(_ctx.amountUSD(i) === 0 ? '-' : _ctx.fNum(_ctx.amountUSD(i), 'usd')), 1),
                      (!_ctx.isStableLikePool)
                        ? (_openBlock(), _createBlock("span", _hoisted_14, _toDisplayString(_ctx.fNum(_ctx.tokenWeights[i], 'percent_lg')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128))
            ])
          ], 64))
        : (_openBlock(), _createBlock("div", _hoisted_15, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pool.tokenAddresses, (token, i) => {
              return (_openBlock(), _createBlock(_component_BalTextInput, {
                key: i,
                name: token,
                modelValue: _ctx.amounts[i],
                "onUpdate:modelValue": ($event: any) => (_ctx.amounts[i] = $event),
                rules: _ctx.amountRules(i),
                type: "number",
                min: "0",
                step: "any",
                placeholder: "0",
                "decimal-limit": _ctx.tokenDecimals(i),
                "validate-on": "input",
                "prepend-border": "",
                "faded-out": _ctx.isSingleAsset && _ctx.singleAsset !== i,
                onClick: ($event: any) => (_ctx.setSingleAsset(i))
              }, _createSlots({
                prepend: _withCtx(() => [
                  _createVNode("div", _hoisted_16, [
                    _createVNode(_component_BalAsset, { address: token }, null, 8, ["address"]),
                    _createVNode("div", _hoisted_17, [
                      _createVNode("span", _hoisted_18, _toDisplayString(_ctx.pool.onchain.tokens[token].symbol), 1)
                    ])
                  ])
                ]),
                append: _withCtx(() => [
                  _createVNode("div", _hoisted_19, [
                    _createVNode(_component_BalBtn, {
                      size: "xs",
                      color: "white",
                      onClick: _withModifiers(($event: any) => (_ctx.amounts[i] = _ctx.singleAssetMaxes[i]), ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('max')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 2
              }, [
                (_ctx.isSingleAsset)
                  ? {
                      name: "info",
                      fn: _withCtx(() => [
                        _createVNode("div", {
                          class: "cursor-pointer",
                          onClick: _withModifiers(($event: any) => (_ctx.amounts[i] = _ctx.singleAssetMaxes[i]), ["prevent"])
                        }, _toDisplayString(_ctx.$t('singleTokenMax')) + ": " + _toDisplayString(_ctx.singleAssetMaxLabel(i)), 9, ["onClick"])
                      ])
                    }
                  : undefined
              ]), 1032, ["name", "modelValue", "onUpdate:modelValue", "rules", "decimal-limit", "faded-out", "onClick"]))
            }), 128))
          ])),
      _createVNode("div", _hoisted_20, [
        (!_ctx.isWalletReady)
          ? (_openBlock(), _createBlock(_component_BalBtn, {
              key: 0,
              label: _ctx.$t('connectWallet'),
              block: "",
              onClick: _withModifiers(_ctx.toggleWalletSelectModal, ["prevent"])
            }, null, 8, ["label", "onClick"]))
          : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
              (_ctx.inputAmountExceedsPoolBalance)
                ? (_openBlock(), _createBlock("div", {
                    key: 0,
                    class: "flex items-center text-sm mb-4 text-red-500 font-medium",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                  }, _toDisplayString(_ctx.$t('inputAmountExceedsPoolBalance')), 1))
                : (_openBlock(), _createBlock("div", {
                    key: 1,
                    class: ['flex items-center text-sm mb-4', _ctx.priceImpactClasses],
                    onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
                  }, [
                    _createVNode("span", null, _toDisplayString(_ctx.$t('priceImpact')) + ": " + _toDisplayString(_ctx.fNum(_ctx.priceImpact, 'percent')), 1),
                    _createVNode(_component_BalTooltip, null, {
                      activator: _withCtx(() => [
                        (_ctx.priceImpact >= 0.01)
                          ? (_openBlock(), _createBlock(_component_BalIcon, {
                              key: 0,
                              name: "alert-triangle",
                              size: "xs",
                              class: "ml-1"
                            }))
                          : (_openBlock(), _createBlock(_component_BalIcon, {
                              key: 1,
                              name: "info",
                              size: "xs",
                              class: "text-gray-400 -mb-px ml-2"
                            }))
                      ]),
                      default: _withCtx(() => [
                        _createVNode("div", {
                          innerHTML: _ctx.$t('withdrawWarning'),
                          class: "w-52"
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ], 2)),
              (_ctx.priceImpact >= 0.01)
                ? (_openBlock(), _createBlock(_component_BalCheckbox, {
                    key: 2,
                    modelValue: _ctx.highPiAccepted,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.highPiAccepted = $event)),
                    rules: [_ctx.isRequired(_ctx.$t('priceImpactCheckbox'))],
                    name: "highPiAccepted",
                    class: "text-gray-500 mb-8",
                    size: "sm",
                    label: _ctx.$t('priceImpactAccept')
                  }, null, 8, ["modelValue", "rules", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_BalBtn, {
                type: "submit",
                "loading-label": _ctx.$t('confirming'),
                color: "gradient",
                disabled: !_ctx.hasAmounts || _ctx.inputAmountExceedsPoolBalance,
                loading: _ctx.loading,
                block: "",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.trackGoal(_ctx.Goals.ClickWithdraw)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('withdraw')) + " " + _toDisplayString(_ctx.missingPrices || _ctx.total.length > 15 ? '' : _ctx.total), 1)
                ]),
                _: 1
              }, 8, ["loading-label", "disabled", "loading"]),
              (_ctx.hasStakedBpt)
                ? (_openBlock(), _createBlock(_component_BalAlert, {
                    key: 3,
                    title: "You have BPT staked in the farm",
                    description: "To withdraw your funds, you need to first unstake your BPT from the farm.",
                    type: "warning",
                    size: "sm",
                    class: "mt-4"
                  }))
                : _createCommentVNode("", true)
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["onOnSubmit"]))
}