
import { defineComponent, toRefs, computed, ref, PropType } from 'vue';
import useNumbers from '@/composables/useNumbers';
import TradePairToggle from '@/components/cards/TradeCard/TradePairToggle.vue';
import SelectTokenModal from '@/components/modals/SelectTokenModal/SelectTokenModal.vue';
import useTokens from '@/composables/useTokens';
import { UseTrading } from '@/composables/trade/useTrading';
import { NATIVE_ASSET_ADDRESS } from '@/constants/tokens';

const ETH_BUFFER = 0.1;

export default defineComponent({
  components: {
    TradePairToggle,
    SelectTokenModal
  },
  props: {
    tokenInAmountInput: {
      type: String,
      required: true
    },
    tokenInAddressInput: {
      type: String,
      required: true
    },
    tokenOutAmountInput: {
      type: String,
      required: true
    },
    tokenOutAddressInput: {
      type: String,
      required: true
    },
    exactIn: {
      type: Boolean,
      required: true
    },
    effectivePriceMessage: {
      type: Object as PropType<UseTrading['effectivePriceMessage']>,
      required: true
    }
  },
  emits: [
    'tokenInAddressChange',
    'tokenInAmountChange',
    'tokenOutAddressChange',
    'tokenOutAmountChange',
    'exactInChange',
    'change'
  ],
  setup(props, { emit }) {
    // COMPOSABLES
    const { fNum, toFiat } = useNumbers();

    // DATA
    const {
      tokenInAmountInput,
      tokenInAddressInput,
      tokenOutAmountInput,
      tokenOutAddressInput,
      exactIn
    } = toRefs(props);

    const isInRate = ref(true);
    const modalSelectTokenType = ref('input');
    const modalSelectTokenIsOpen = ref(false);

    const { tokens, balances, injectTokens } = useTokens();

    const tokenInValue = computed(() =>
      toFiat(tokenInAmountInput.value, tokenInAddressInput.value)
    );

    const tokenInSymbol = computed(() => {
      const tokenIn = tokens.value[tokenInAddressInput.value];
      const symbol = tokenIn ? tokenIn.symbol : '';
      return symbol;
    });

    const tokenOutValue = computed(() =>
      toFiat(tokenOutAmountInput.value, tokenOutAddressInput.value)
    );

    const tokenOutSymbol = computed(() => {
      const tokenOut = tokens.value[tokenOutAddressInput.value];
      const symbol = tokenOut ? tokenOut.symbol : '';
      return symbol;
    });

    const tokenInBalance = computed(
      () => balances.value[tokenInAddressInput.value] || '0'
    );

    const tokenOutBalance = computed(
      () => balances.value[tokenOutAddressInput.value] || '0'
    );

    // METHODS

    function getMaxAmount(
      tokenAddress: string,
      balance: string,
      balanceNumber: number
    ) {
      return tokenAddress !== NATIVE_ASSET_ADDRESS
        ? balance
        : balanceNumber > ETH_BUFFER
        ? (balanceNumber - ETH_BUFFER).toString()
        : '0';
    }
    function handleInMax() {
      const balance = tokenInBalance.value;
      const balanceNumber = parseFloat(balance);
      const maxAmount = getMaxAmount(
        tokenInAddressInput.value,
        balance,
        balanceNumber
      );

      handleInAmountChange(maxAmount);
    }

    function handleOutMax() {
      const balance = tokenOutBalance.value;
      const balanceNumber = parseFloat(balance);
      const maxAmount = getMaxAmount(
        tokenOutAddressInput.value,
        balance,
        balanceNumber
      );
      handleOutAmountChange(maxAmount);
    }

    function handleInAmountChange(value: string): void {
      emit('exactInChange', true);
      emit('tokenInAmountChange', value);
      emit('change', value);
    }

    function handleOutAmountChange(value: string): void {
      emit('exactInChange', false);
      emit('tokenOutAmountChange', value);
      emit('change', value);
    }

    function handleSwitchTokens(): void {
      emit('exactInChange', !exactIn.value);
      emit('tokenInAmountChange', tokenOutAmountInput.value);
      emit('tokenInAddressChange', tokenOutAddressInput.value);
      emit('tokenOutAmountChange', tokenInAmountInput.value);
      emit('tokenOutAddressChange', tokenInAddressInput.value);
    }

    function handleSelectToken(address: string): void {
      if (modalSelectTokenType.value === 'input') {
        if (address === tokenOutAddressInput.value) {
          handleSwitchTokens();
          return;
        } else emit('tokenInAddressChange', address);
      } else {
        if (address === tokenInAddressInput.value) {
          handleSwitchTokens();
          return;
        } else emit('tokenOutAddressChange', address);
      }
      injectTokens([address]);
    }

    const rateMessage = computed(() =>
      isInRate.value
        ? props.effectivePriceMessage.value.tokenIn
        : props.effectivePriceMessage.value.tokenOut
    );

    function toggleRate(): void {
      isInRate.value = !isInRate.value;
    }

    function openModalSelectToken(type: string): void {
      modalSelectTokenIsOpen.value = true;
      modalSelectTokenType.value = type;
    }

    return {
      tokens,
      fNum,
      handleInMax,
      handleOutMax,
      tokenInBalance,
      tokenOutBalance,
      handleInAmountChange,
      handleOutAmountChange,
      handleSwitchTokens,
      rateMessage,
      toggleRate,
      tokenInValue,
      tokenInSymbol,
      tokenOutValue,
      tokenOutSymbol,
      modalSelectTokenIsOpen,
      openModalSelectToken,
      handleSelectToken
    };
  }
});
