
import { defineComponent, PropType, ref, watch } from 'vue';

type FormTypes = 'proportional' | 'custom';

interface FormType {
  label: string;
  max: string;
  value: FormTypes;
  tooltip?: string;
}

export default defineComponent({
  name: 'TypeToggle',

  emits: ['update:modelValue'],

  props: {
    formTypes: { type: Object as PropType<FormType[]>, required: true },
    modelValue: { type: String, required: true },
    loading: { type: Boolean, default: false },
    hasZeroBalance: { type: Boolean, default: false },
    missingPrices: { type: Boolean, default: false }
  },

  setup(props, { emit }) {
    const selected = ref(props.formTypes[0].value);

    watch(selected, newVal => {
      emit('update:modelValue', newVal);
    });

    watch(
      () => props.modelValue,
      newVal => {
        if (selected.value != newVal) selected.value = newVal as FormTypes;
      }
    );

    return {
      selected
    };
  }
});
