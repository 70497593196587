
import { defineComponent, PropType } from 'vue';

import useNumbers from '@/composables/useNumbers';
import { DecoratedPool } from '@/services/balancer/subgraph/types';

export default defineComponent({
  name: 'LiquidityMiningTooltip',

  props: {
    pool: {
      type: Object as PropType<DecoratedPool>,
      required: true
    }
  },

  setup() {
    const { fNum } = useNumbers();

    return {
      fNum
    };
  }
});
