
import { computed, defineComponent, PropType } from 'vue';
import { Alert } from '@/store/modules/alerts';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NavAlert',

  props: {
    alert: { type: Object as PropType<Alert>, required: true }
  },

  setup(props) {
    const store = useStore();

    const colorClass = computed(() => {
      switch (props.alert.type) {
        case 'error':
          return 'bg-red-500 text-white';
        default:
          return 'bg-black text-white';
      }
    });

    const iconName = computed(() => {
      switch (props.alert.type) {
        case 'error':
          return 'alert-triangle';
        default:
          return 'info';
      }
    });

    const classes = computed(() => {
      return {
        [colorClass.value]: true
      };
    });

    function handleClose() {
      store.commit('alerts/setCurrent', null);
    }

    return { classes, iconName, handleClose };
  }
});
