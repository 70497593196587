import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rounded-full h-2 w-2 flex bg-yellow-500 absolute top-4 -right-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", {
      class: ['bal-tab-container', _ctx.containerClasses]
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
        return (_openBlock(), _createBlock("div", {
          key: i,
          class: ['bal-tab', 'relative', _ctx.stateClasses(tab)],
          onClick: ($event: any) => (_ctx.onClick(tab))
        }, [
          _createTextVNode(_toDisplayString(tab.label) + " ", 1),
          (tab.alert)
            ? (_openBlock(), _createBlock("div", _hoisted_1))
            : _createCommentVNode("", true)
        ], 10, ["onClick"]))
      }), 128))
    ], 2)
  ]))
}