import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dark:border-b dark:border-gray-500" }
const _hoisted_2 = { class: "p-2 flex justify-between text-sm rounded-t-lg dark:bg-gray-900 border dark:border-gray-500 w-full border-b-0" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-500"
}
const _hoisted_4 = { class: "flex flex-col ml-3 w-24 leading-none truncate" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_7 = { class: "p-2" }
const _hoisted_8 = { class: "flex items-center mb-4" }
const _hoisted_9 = {
  key: 0,
  class: "flex-auto ml-4"
}
const _hoisted_10 = { class: "dark:border-b dark:border-gray-500" }
const _hoisted_11 = { class: "p-2 flex justify-between text-sm rounded-t-lg dark:bg-gray-900 border dark:border-gray-500 w-full border-b-0" }
const _hoisted_12 = {
  key: 0,
  class: "text-gray-500"
}
const _hoisted_13 = { class: "flex flex-col ml-3 w-24 leading-none truncate" }
const _hoisted_14 = { class: "font-semibold" }
const _hoisted_15 = {
  key: 1,
  class: "font-semibold"
}
const _hoisted_16 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAsset = _resolveComponent("BalAsset")
  const _component_BalTooltip = _resolveComponent("BalTooltip")
  const _component_BalIcon = _resolveComponent("BalIcon")
  const _component_BalBtn = _resolveComponent("BalBtn")
  const _component_BalTextInput = _resolveComponent("BalTextInput")
  const _component_TradePairToggle = _resolveComponent("TradePairToggle")
  const _component_SelectTokenModal = _resolveComponent("SelectTokenModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", null, _toDisplayString(_ctx.$t('send')), 1),
          (_ctx.tokenInValue > 0)
            ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.fNum(_ctx.tokenInValue, 'usd')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_BalTextInput, {
        name: 'tokenIn',
        "model-value": _ctx.tokenInAmountInput,
        onInput: _cache[3] || (_cache[3] = value => _ctx.handleInAmountChange(value)),
        type: "number",
        min: "0",
        step: "any",
        placeholder: "0",
        "validate-on": "input",
        "square-top": "",
        "prepend-border": ""
      }, {
        prepend: _withCtx(() => [
          _createVNode("div", {
            class: "flex items-center w-32 h-full cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModalSelectToken('input')))
          }, [
            (_ctx.tokenInAddressInput)
              ? (_openBlock(), _createBlock(_component_BalAsset, {
                  key: 0,
                  address: _ctx.tokenInAddressInput,
                  size: 28
                }, null, 8, ["address"]))
              : _createCommentVNode("", true),
            _createVNode("div", _hoisted_4, [
              (_ctx.tokenInSymbol.length > 5)
                ? (_openBlock(), _createBlock(_component_BalTooltip, { key: 0 }, {
                    activator: _withCtx(() => [
                      _createVNode("span", _hoisted_5, _toDisplayString(_ctx.tokenInSymbol), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode("div", null, _toDisplayString(_ctx.tokenInSymbol), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock("span", _hoisted_6, _toDisplayString(_ctx.tokenInSymbol), 1))
            ]),
            _createVNode(_component_BalIcon, {
              name: 'chevron-down',
              size: 'sm',
              class: "text-green-500 group-hover:text-red-500"
            })
          ])
        ]),
        info: _withCtx(() => [
          _createVNode("div", {
            class: "cursor-pointer",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInMax && _ctx.handleInMax(...args)))
          }, _toDisplayString(_ctx.$t('balance')) + ": " + _toDisplayString(_ctx.fNum(_ctx.tokenInBalance, 'token')), 1)
        ]),
        append: _withCtx(() => [
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_BalBtn, {
              size: "xs",
              color: "white",
              onClick: _ctx.handleInMax
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('max')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["model-value"]),
      _createVNode("div", _hoisted_8, [
        _createVNode(_component_TradePairToggle, { onToggle: _ctx.handleSwitchTokens }, null, 8, ["onToggle"]),
        (_ctx.rateMessage)
          ? (_openBlock(), _createBlock("div", _hoisted_9, [
              _createVNode("span", {
                class: "text-sm text-gray-500 cursor-pointer",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleRate && _ctx.toggleRate(...args))),
                textContent: _toDisplayString(_ctx.rateMessage)
              }, null, 8, ["textContent"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode("div", _hoisted_10, [
        _createVNode("div", _hoisted_11, [
          _createVNode("div", null, _toDisplayString(_ctx.$t('receive')), 1),
          (_ctx.tokenOutValue > 0)
            ? (_openBlock(), _createBlock("div", _hoisted_12, _toDisplayString(_ctx.fNum(_ctx.tokenOutValue, 'usd')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_BalTextInput, {
        name: 'tokenOut',
        "model-value": _ctx.tokenOutAmountInput,
        onInput: _cache[7] || (_cache[7] = value => _ctx.handleOutAmountChange(value)),
        type: "number",
        min: "0",
        step: "any",
        placeholder: "0",
        "validate-on": "input",
        "prepend-border": "",
        "square-top": ""
      }, {
        prepend: _withCtx(() => [
          _createVNode("div", {
            class: "flex items-center w-32 h-full cursor-pointer",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openModalSelectToken('output')))
          }, [
            (_ctx.tokenOutAddressInput)
              ? (_openBlock(), _createBlock(_component_BalAsset, {
                  key: 0,
                  address: _ctx.tokenOutAddressInput,
                  size: 28
                }, null, 8, ["address"]))
              : _createCommentVNode("", true),
            _createVNode("div", _hoisted_13, [
              (_ctx.tokenOutSymbol.length > 5)
                ? (_openBlock(), _createBlock(_component_BalTooltip, { key: 0 }, {
                    activator: _withCtx(() => [
                      _createVNode("span", _hoisted_14, _toDisplayString(_ctx.tokenOutSymbol), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode("div", null, _toDisplayString(_ctx.tokenOutSymbol), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock("span", _hoisted_15, _toDisplayString(_ctx.tokenOutSymbol), 1))
            ]),
            _createVNode(_component_BalIcon, {
              name: 'chevron-down',
              size: 'sm',
              class: "text-green-500 group-hover:text-red-500"
            })
          ])
        ]),
        info: _withCtx(() => [
          _createVNode("div", {
            class: "cursor-pointer",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleOutMax && _ctx.handleOutMax(...args)))
          }, _toDisplayString(_ctx.$t('balance')) + ": " + _toDisplayString(_ctx.fNum(_ctx.tokenOutBalance, 'token')), 1)
        ]),
        append: _withCtx(() => [
          _createVNode("div", _hoisted_16, [
            _createVNode(_component_BalBtn, {
              size: "xs",
              color: "white",
              onClick: _ctx.handleOutMax
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('max')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (_ctx.modalSelectTokenIsOpen)
        ? (_openBlock(), _createBlock(_component_SelectTokenModal, {
            key: 0,
            excludedTokens: [_ctx.tokenInAddressInput, _ctx.tokenOutAddressInput],
            onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.modalSelectTokenIsOpen = false)),
            onSelect: _ctx.handleSelectToken,
            "include-ether": ""
          }, null, 8, ["excludedTokens", "onSelect"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}