import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex relative h-10 items-center px-2" }
const _hoisted_2 = { class: "px-2 py-1" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = {
  key: 0,
  class: "absolute w-1 h-12 -mt-6 ml-px bg-white dark:bg-gray-850 group-hover:bg-gray-50 dark:group-hover:bg-gray-800 transform rotate-12"
}
const _hoisted_5 = {
  key: 0,
  class: "w-3 h-3 rounded-full border-2 border-white dark:border-gray-850 group-hover:border-gray-50 dark:group-hover:border-gray-800   bg-green-200 dark:bg-green-500 absolute top-0 right-0 -mt-1 -mr-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAsset = _resolveComponent("BalAsset")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_BalAsset, {
      address: _ctx.address,
      size: 24
    }, null, 8, ["address"]),
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.symbol), 1),
    _createVNode("div", _hoisted_3, [
      (!_ctx.isLast)
        ? (_openBlock(), _createBlock("div", _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hasBalance)
      ? (_openBlock(), _createBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}